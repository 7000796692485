import { forwardRef } from "react";
import { Form, InputNumber, InputNumberProps } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";
import { numberFormatter, numberParser } from "@ni/common/utils";

import { FormItemLabel } from "../../Labels";

export type NetworkNumberProps = { formItemOptions?: NetWorkFormItemProps } & InputNumberProps;

export const Number = forwardRef<HTMLInputElement, NetworkNumberProps>(({ formItemOptions, ...props }, ref) => {
  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        label={<FormItemLabel label={formItemOptions?.label as string} code={formItemOptions?.name as string} />}
      >
        <InputNumber
          ref={ref}
          formatter={(value, info) => numberFormatter(value, info, props?.precision ?? 0)}
          parser={numberParser}
          controls={false}
          {...props}
        />
      </Form.Item>
    );
  }
  return (
    <InputNumber
      ref={ref}
      formatter={(value, info) => numberFormatter(value, info, props?.precision ?? 0)}
      parser={numberParser}
      controls={false}
      {...props}
    />
  );
});
