import { FC } from "react";
import { Button } from "antd";
import cn from "classnames";

import { useUATDisableForm } from "@ni/common/hooks";

import styles from "./styles.module.scss";

interface PctListItemProps {
  rowId: string;
  externalCode?: string;
  pctId: number;
  displayedName?: string;
  isLoading: boolean;
  renamePctHandler: (pct: number) => void;
  editPctHandler: (pct: number) => void;
  duplicateClickHandler: (pct: number) => void;
}

export const PctListItem: FC<PctListItemProps> = ({
  rowId,
  externalCode,
  pctId,
  displayedName,
  isLoading,
  renamePctHandler,
  editPctHandler,
  duplicateClickHandler,
}: PctListItemProps) => {
  const isDisabled = useUATDisableForm();

  return (
    <div className={styles["list-item"]}>
      <div className={styles["pct-external-code"]}>{externalCode || `00${rowId}`.slice(-3)}</div>
      <div className={styles["pct-displayed-name"]} title={displayedName}>
        {displayedName}
      </div>
      <Button
        loading={isLoading}
        className={cn(styles["pct-button"], styles["rename"])}
        type="default"
        size="small"
        disabled={isDisabled}
        onClick={() => renamePctHandler(pctId)}
      >
        Rename
      </Button>
      <Button
        loading={isLoading}
        className={cn(styles["pct-button"], styles["edit"])}
        type="default"
        size="small"
        onClick={() => editPctHandler(pctId)}
      >
        {isDisabled ? "View" : "Edit"}
      </Button>
      <Button
        loading={isLoading}
        className={cn(styles["pct-button"], styles["duplicate"])}
        type="default"
        size="small"
        disabled={isDisabled}
        onClick={() => duplicateClickHandler(pctId)}
      >
        Duplicate
      </Button>
    </div>
  );
};

PctListItem.defaultProps = {
  displayedName: undefined,
};
