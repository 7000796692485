import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { BAL_INQ_ENABLED, NIC_BAL_INQ_FEE_OTHER_MAX_NRR, NIC_BAL_INQ_FEE_OTHER_VAL } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const feeMessage = "Default fee amount is required";

export const BalanceInquiryFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm<FormValues>();
  const isEnabled = Form.useWatch<boolean>(BAL_INQ_ENABLED, form);
  const feeValue = Form.useWatch<number>(NIC_BAL_INQ_FEE_OTHER_VAL, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "",
    [wizardResponse.product?.productValues],
  );

  const productValues = useMemo(
    () => wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
    [wizardResponse.product?.parameterTables],
  );

  const formDisabledFn = useCallback(
    (field: string, number: string | number) =>
      formDisabled ? (getFormValueFromProductValues(productValues, field, "string") as string) || "" : number,
    [formDisabled, productValues],
  );

  useEffect(() => {
    form.setFieldsValue({
      [BAL_INQ_ENABLED]: formDisabled ? getFormValueFromProductValues(productValues, BAL_INQ_ENABLED, "boolean") : true,
      [NIC_BAL_INQ_FEE_OTHER_VAL]: formDisabledFn(NIC_BAL_INQ_FEE_OTHER_VAL, "3.5"),
      [NIC_BAL_INQ_FEE_OTHER_MAX_NRR]: formDisabledFn(NIC_BAL_INQ_FEE_OTHER_MAX_NRR, ""),
    });
  }, [form, formDisabled, formDisabledFn, productValues]);

  const switchClick = () => {
    if (isEnabled && !feeValue) {
      form.setFields([
        {
          name: NIC_BAL_INQ_FEE_OTHER_VAL,
          errors: [],
        },
      ]);
    }

    if (!isEnabled && !feeValue) {
      form.setFields([
        {
          name: NIC_BAL_INQ_FEE_OTHER_VAL,
          errors: [feeMessage],
        },
      ]);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: isEnabled
        ? {
            [BAL_INQ_ENABLED]: String(isEnabled),
            [NIC_BAL_INQ_FEE_OTHER_VAL]: String(feeValue).replace(/ /g, ""),
            [NIC_BAL_INQ_FEE_OTHER_MAX_NRR]: String(form.getFieldValue(NIC_BAL_INQ_FEE_OTHER_MAX_NRR)).replace(
              / /g,
              "",
            ),
          }
        : {
            [BAL_INQ_ENABLED]: String(isEnabled),
          },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isEnabled && +feeValue <= 0}
      pageTitle="Balance Inquiry Fee"
      pageSubtitle="Balance inquiry fee is a fixed amount that can be charged to your customer in case of successful balance
      inquiry through ATMs or POS"
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item name={BAL_INQ_ENABLED} initialValue={true} valuePropName="checked" className="col-switch">
          <Switch onChange={switchClick} />
        </Form.Item>
        <TooltipInfo
          label="Enable balance inquire fee for your product"
          code={BAL_INQ_ENABLED}
          tooltipProps={{
            title:
              "You can configure fee setup  for all transactions, or you may specify different ones for On-Us & domestic level after wizard completion from Pricing control tables menu on product settings",
          }}
        />
      </Space>

      <Form.Item dependencies={[BAL_INQ_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_BAL_INQ_FEE_OTHER_VAL}
              rules={[{ required: isEnabled, message: feeMessage }]}
              label={<FormItemLabel label="Default fee amount" code={NIC_BAL_INQ_FEE_OTHER_VAL} />}
            >
              <NetworkForm.Number precision={2} min={0} addonAfter={currency} disabled={!isEnabled || formDisabled} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item dependencies={[BAL_INQ_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_BAL_INQ_FEE_OTHER_MAX_NRR}
              label={
                <FormItemLabel label="Free balance inquiries per billing cycle" code={NIC_BAL_INQ_FEE_OTHER_MAX_NRR} />
              }
              tooltip={{
                title: "This will be applied for all balance transactions following default fee setting",
                icon: <QuestionCircleFilled />,
              }}
            >
              <NetworkForm.Number
                disabled={!isEnabled || formDisabled}
                decimalSeparator="."
                min={0}
                precision={0}
                step={1}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </CustomFormWrapper>
  );
};
