import { FC } from "react";
import { Form, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { useProductCurrencyType } from "../../hooks";

import { enableOverlimitFee, OverlimitFeeOptions, OverlimitTolerancePercentageOptions } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const OverlimitFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [enableOverlimitFee.code, OverlimitFeeOptions.code, OverlimitTolerancePercentageOptions.code],
    },
    allowParse: true,
  });

  const isOverlimitEnabled = Form.useWatch<boolean>(enableOverlimitFee.code, form);
  const currencyType = useProductCurrencyType(wizardResponse.product?.productValues);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Over-limit Fee"
      pageSubtitle="Overlimit fee is charged when the utilization of a card exceeds the allowed credit limit."
      size="md"
      formSize="full"
      gap={30}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item name={enableOverlimitFee.code} valuePropName="checked" initialValue={enableOverlimitFee.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo label={enableOverlimitFee.name} code={enableOverlimitFee.code} tooltipProps={{}} />
      </Space>
      {isOverlimitEnabled && (
        <>
          <Form.Item
            name={OverlimitFeeOptions.code}
            label={<FormItemLabel label={OverlimitFeeOptions.name} code={OverlimitFeeOptions.code} />}
            rules={[{ required: true, message: "Overlimit Fee value is required!" }]}
          >
            <NetworkForm.Number
              addonAfter={currencyType}
              min={OverlimitFeeOptions.min}
              precision={OverlimitFeeOptions.decimalsNumber}
            />
          </Form.Item>
          <Form.Item
            name={OverlimitTolerancePercentageOptions.code}
            label={
              <FormItemLabel
                label={OverlimitTolerancePercentageOptions.name}
                code={OverlimitTolerancePercentageOptions.code}
              />
            }
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: OverlimitTolerancePercentageOptions.tooltip,
            }}
          >
            <NetworkForm.Number
              addonAfter="%"
              min={OverlimitTolerancePercentageOptions.min}
              precision={OverlimitTolerancePercentageOptions.decimalsNumber}
            />
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
