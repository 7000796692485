import { FC, ReactNode, useEffect, useMemo } from "react";
import { Col, Form, FormInstance, Row } from "antd";
import classNames from "classnames";

import { FeeField, FeeFieldType, FormValues } from "@ni/common/types";

import { NetworkForm } from "../FormInput";
import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface FeesValuesRowProps {
  rowLabel?: ReactNode;
  rowTooltip?: string;
  productCurrency: string;
  form: FormInstance<FormValues>;
  fields: FeeField[];
}

const getField = (key: FeeFieldType, fields: FeeField[]) => fields.find(field => field.type === key);

export const FeesValuesRow: FC<FeesValuesRowProps> = ({ rowLabel, rowTooltip, productCurrency, form, fields }) => {
  const isFeeAvailable = Form.useWatch<string>(getField("percentage", fields)?.name ?? "", form);
  const minFieldName = useMemo(() => getField("min", fields)?.name ?? "", [fields]);
  const maxnFieldName = useMemo(() => getField("max", fields)?.name ?? "", [fields]);

  useEffect(() => {
    if (!isFeeAvailable && !form.getFieldValue(getField("percentage", fields)?.name))
      form.resetFields([minFieldName, maxnFieldName]);
  }, [fields, form, isFeeAvailable, maxnFieldName, minFieldName]);

  return (
    <Row className={styles["fees-row-inputs-wrapper"]}>
      <Col span={4}>
        <div className={classNames("ant-form-vertical ant-form-item-label", styles["first-row-cell"])}>
          {rowTooltip ? (
            <TooltipInfo label={rowLabel as string} tooltipProps={{ title: rowTooltip, className: "" }} />
          ) : (
            rowLabel
          )}
        </div>
      </Col>

      <Col span={20}>
        <Row className={styles["fees-row-inputs-wrapper"]}>
          {fields.map(field => {
            return (
              <Col key={field.name} span={Math.round(24 / fields.length)} className={styles["fees-row-input"]}>
                <Form.Item
                  name={field.name}
                  dependencies={field?.dependencies && field?.dependencies.length > 0 ? field.dependencies : undefined}
                >
                  <Form.Item name={field.name}>
                    <NetworkForm.Number
                      min={0}
                      precision={2}
                      addonAfter={field.type === "percentage" ? "%" : productCurrency}
                      disabled={(field.type === "min" || field.type === "max") && !isFeeAvailable}
                      {...field.props}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
