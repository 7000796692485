import { FC } from "react";
import { Form, Space, Switch, Typography } from "antd";
import { FormInstance } from "rc-field-form";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  INQ_ALLOW_DOM,
  INQ_ALLOW_ONUS,
  NIC_BAL_INQ_FEE_DOM_MAX_NR,
  NIC_BAL_INQ_FEE_DOM_VAL,
  NIC_BAL_INQ_FEE_ONUS_MAX_NR,
  NIC_BAL_INQ_FEE_OTHER_ONUS_VAL,
} from "@ni/common/constants";
import { NetworkForm, PageItemLayoutGeneral, TooltipInfo } from "@ni/common/ui";

import styles from "../../styles.module.scss";

interface CustomizedInquiryFeesProps {
  isEnabled: boolean;
  productCurrency: string;
  form: FormInstance;
}

export const CustomizedInquiryFees: FC<CustomizedInquiryFeesProps> = ({ isEnabled, productCurrency, form }) => {
  const inqAllowOnus = Form.useWatch<string>(INQ_ALLOW_ONUS, form);
  const inqAllowDom = Form.useWatch<string>(INQ_ALLOW_DOM, form);

  return (
    <div className={styles["customized-inquiry-fees"]}>
      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Form.Item>
          <Space direction="horizontal">
            <Form.Item name={INQ_ALLOW_ONUS} valuePropName="checked">
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>
              <TooltipInfo
                label="Apply custom fee on OnUs balance inquires"
                tooltipProps={{
                  title:
                    "Enable this if you want to specify different fee for balance inquiries through your acceptance network (ATM, POS) other than Default fee setting",
                }}
              />
            </Typography.Text>
          </Space>
        </Form.Item>
        <Form.Item dependencies={[INQ_ALLOW_ONUS]} shouldUpdate={true}>
          {() => {
            return (
              <Form.Item
                name={NIC_BAL_INQ_FEE_OTHER_ONUS_VAL}
                rules={[
                  {
                    required: (form.getFieldValue(INQ_ALLOW_ONUS) as boolean) || false,
                    message: "OnUs balance inquiry fee amount is required",
                  },
                ]}
                label="OnUs balance inquiry fee amount"
              >
                <NetworkForm.Number
                  min={0}
                  precision={2}
                  addonAfter={productCurrency}
                  disabled={!isEnabled || !inqAllowOnus}
                  stringMode={true}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item dependencies={[INQ_ALLOW_ONUS]} shouldUpdate={true}>
          {() => {
            return (
              <Form.Item
                name={NIC_BAL_INQ_FEE_ONUS_MAX_NR}
                label="Free OnUs balance inquiries per billing cycle"
                tooltip={{
                  title: "Specify number of free of charge balance inquiries per statement cycle",
                  icon: <QuestionCircleFilled />,
                }}
              >
                <NetworkForm.Number disabled={!isEnabled || !inqAllowOnus} min={0} precision={0} step={1} />
              </Form.Item>
            );
          }}
        </Form.Item>

        {inqAllowOnus && (
          <div className="alert-div">
            Please reach out to your Network International representative for providing your acquiring process details
            to segregate OnUs transactions.
          </div>
        )}
      </PageItemLayoutGeneral>
      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Form.Item>
          <Space direction="horizontal">
            <Form.Item name={INQ_ALLOW_DOM} valuePropName="checked">
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>
              <TooltipInfo
                label="Apply custom fee on domestic balance inquiries"
                tooltipProps={{
                  title:
                    "Enable this if you want to specify different fee for domestic balance inquiry transactions other than the Default fee setting",
                }}
              />
            </Typography.Text>
          </Space>
        </Form.Item>
        <Form.Item dependencies={[INQ_ALLOW_DOM]} shouldUpdate={true}>
          {() => {
            return (
              <Form.Item
                name={NIC_BAL_INQ_FEE_DOM_VAL}
                rules={[
                  {
                    required: (form.getFieldValue(INQ_ALLOW_DOM) as boolean) || false,
                    message: "Domestic balance inquiry fee amount is required",
                  },
                ]}
                label="Domestic balance inquiry fee amount"
              >
                <NetworkForm.Number
                  stringMode={true}
                  min={0}
                  precision={2}
                  addonAfter={productCurrency}
                  disabled={!isEnabled || !inqAllowDom}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item dependencies={[INQ_ALLOW_DOM]} shouldUpdate={true}>
          {() => {
            return (
              <Form.Item
                name={NIC_BAL_INQ_FEE_DOM_MAX_NR}
                label="Free domestic balance inquiries per cycle"
                tooltip={{
                  title: "Specify number of free of charge balance inquiries per statement cycle",
                  icon: <QuestionCircleFilled />,
                }}
              >
                <NetworkForm.Number disabled={!isEnabled || !inqAllowDom} min={0} precision={0} step={1} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </PageItemLayoutGeneral>
    </div>
  );
};
