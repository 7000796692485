import React, { FC, useEffect, useMemo } from "react";
import { Col, Form, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, getSwitchesCount } from "@ni/common/utils";
import { ProductValue, TenantProductWizardResponse } from "@ni/sdk/models";

import { checkHeader, headerSwitchHandler } from "../../../utils";

import { checkboxFieldsPage10, switchHeader10 } from "./page10.constants";

interface PctCardDailyProps {
  currency: string;
  header?: string;
  form: FormInstance<FormValues>;
  formDisabled?: boolean;
  editMode?: boolean;
}

export const PctCardDaily: FC<PctCardDailyProps> = ({ currency, header, form, formDisabled, editMode }) => {
  const [wizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const productTransPresetValues = useMemo(() => {
    const pctProductValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues as ProductValue[];

    return {
      transCatContC: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("C"),
      transAtmPosTrue: getFormValueFromProductValues(pctProductValues, "trans-lim-atm-pos", "boolean") === true,
      transCatContR: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("R"),
      transEcomTrue: getFormValueFromProductValues(pctProductValues, "trans-lim-ecom", "boolean") === true,
      transCatContT: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("T"),
    };
  }, [wizardResponse]);

  useEffect(() => {
    Object.keys(checkboxFieldsPage10).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });

      const checkboxFields = checkboxFieldsPage10[key];
      const hasCheckedItem = checkboxFields.some(item => form.getFieldValue(item));

      if (hasCheckedItem) {
        form.setFieldsValue({
          [key]: true,
        });
      }
    });

    checkHeader("card-daily-all-cash-enable", switchHeader10, form);
    checkHeader("card-daily-all-retail-enable", switchHeader10, form);
    checkHeader("card-daily-all-total-enable", switchHeader10, form);
  });

  const isShowAtmPos = useMemo(
    () => productTransPresetValues?.transAtmPosTrue || editMode,
    [productTransPresetValues?.transAtmPosTrue, editMode],
  );

  const isShowEcommerce = useMemo(
    () => productTransPresetValues?.transEcomTrue || editMode,
    [productTransPresetValues?.transEcomTrue, editMode],
  );

  const switchShownCash = {
    all: true,
    atm: isShowAtmPos,
    pos: isShowAtmPos,
  };

  const switchShownRetail = {
    all: true,
    ecommerce: isShowEcommerce,
  };

  const cashSwitchesNotOne = getSwitchesCount(switchShownCash) !== 1;
  const retailSwitchesNotOne = getSwitchesCount(switchShownRetail) !== 1;

  return (
    <>
      {header && <div className="text-blue-h3">{header}</div>}
      {(productTransPresetValues?.transCatContC || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item valuePropName="checked" name="card-daily-all-cash-enable" className="header-switch">
              <Switch
                onClick={value => headerSwitchHandler("card-daily-all-cash-enable", switchHeader10, value, form)}
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Cash"
                code="card-daily-all-cash-enable"
                tooltipProps={{ title: "Turns off all cash limits" }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="nic-c-d-cash-enable" className="col-switch">
                  {cashSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("card-daily-all-cash-enable", switchHeader10, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code="nic-c-d-cash-enable"
                    tooltipProps={{
                      title:
                        "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-cash-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-cash-max-nr">
                        <Form.Item name="nic-c-d-cash-max-nr">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-cash-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-cash-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-cash-max-am">
                        <Form.Item name="nic-c-d-cash-max-am">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-cash-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-cash-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-cash-max-single">
                        <Form.Item name="nic-c-d-cash-max-single">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-cash-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            {isShowAtmPos && (
              <Row>
                <Col span={6}>
                  <Form.Item valuePropName="checked" name="nic-c-d-cash-atm-enable" className="col-switch">
                    {cashSwitchesNotOne && (
                      <Switch onClick={() => checkHeader("card-daily-all-cash-enable", switchHeader10, form)} />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="ATM"
                      code="nic-c-d-cash-atm-enable"
                      tooltipProps={{
                        title:
                          "This limiter counts all ATM transactions. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-cash-atm-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-cash-atm-max-nr">
                          <Form.Item name="nic-c-d-cash-atm-max-nr">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-cash-atm-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-cash-atm-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-cash-atm-max-am">
                          <Form.Item name="nic-c-d-cash-atm-max-am">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-cash-atm-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-cash-atm-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-cash-atm-max-single">
                          <Form.Item name="nic-c-d-cash-atm-max-single">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-cash-atm-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isShowAtmPos && (
              <Row>
                <Col span={6}>
                  <Form.Item valuePropName="checked" name="nic-c-d-cash-pos-enable" className="col-switch">
                    {cashSwitchesNotOne && (
                      <Switch onClick={() => checkHeader("card-daily-all-cash-enable", switchHeader10, form)} />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="POS"
                      code="nic-c-d-cash-pos-enable"
                      tooltipProps={{
                        title:
                          "This limiter counts all cash transactions done at POS terminals. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-cash-pos-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-cash-pos-max-nr">
                          <Form.Item name="nic-c-d-cash-pos-max-nr">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-cash-pos-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-cash-pos-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-cash-pos-max-am">
                          <Form.Item name="nic-c-d-cash-pos-max-am">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-cash-pos-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-cash-pos-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-cash-pos-max-single">
                          <Form.Item name="nic-c-d-cash-pos-max-single">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-cash-pos-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContR || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item valuePropName="checked" name="card-daily-all-retail-enable" className="header-switch">
              <Switch
                onClick={value => headerSwitchHandler("card-daily-all-retail-enable", switchHeader10, value, form)}
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Retail"
                code="card-daily-all-retail-enable"
                tooltipProps={{ title: "Turns off all retail transactions." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="nic-c-d-retail-enable" className="col-switch">
                  {retailSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("card-daily-all-retail-enable", switchHeader10, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code="nic-c-d-retail-enable"
                    tooltipProps={{
                      title:
                        "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-retail-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-retail-max-nr">
                        <Form.Item name="nic-c-d-retail-max-nr">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-retail-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-retail-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-retail-max-am">
                        <Form.Item name="nic-c-d-retail-max-am">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-retail-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-retail-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-retail-max-single">
                        <Form.Item name="nic-c-d-retail-max-single">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-retail-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            {isShowEcommerce && (
              <Row>
                <Col span={6}>
                  <Form.Item valuePropName="checked" name="nic-c-d-ecomm-enable" className="col-switch">
                    {retailSwitchesNotOne && (
                      <Switch onClick={() => checkHeader("card-daily-all-retail-enable", switchHeader10, form)} />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="Ecommerce"
                      code="nic-c-d-ecomm-enable"
                      tooltipProps={{
                        title:
                          "This limiter includes retail transactions over an electronic network, primarily the internet. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-ecomm-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-ecomm-max-nr">
                          <Form.Item name="nic-c-d-ecomm-max-nr">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-ecomm-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-ecomm-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-ecomm-max-am">
                          <Form.Item name="nic-c-d-ecomm-max-am">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-ecomm-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-d-ecomm-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-d-ecomm-max-single">
                          <Form.Item name="nic-c-d-ecomm-max-single">
                            <NetworkForm.Number
                              min={0}
                              addonAfter={currency}
                              disabled={!form.getFieldValue("nic-c-d-ecomm-enable") || formDisabled}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContT || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item valuePropName="checked" name="card-daily-all-total-enable" className="header-switch">
              <Switch
                onClick={value => headerSwitchHandler("card-daily-all-total-enable", switchHeader10, value, form)}
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Total"
                code="card-daily-all-total-enable"
                tooltipProps={{
                  title:
                    "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="nic-c-d-total-enable" className="col-switch" />
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code="nic-c-d-total-enable"
                    tooltipProps={{
                      title:
                        "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-total-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-total-max-nr">
                        <Form.Item name="nic-c-d-total-max-nr">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-total-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-total-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-total-max-am">
                        <Form.Item name="nic-c-d-total-max-am">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-total-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-d-total-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-d-total-max-single">
                        <Form.Item name="nic-c-d-total-max-single">
                          <NetworkForm.Number
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("nic-c-d-total-enable") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

PctCardDaily.defaultProps = {
  header: undefined,
  formDisabled: true,
  editMode: false,
};

export const getCardDailyLimitsPreparedValues = (value: {
  [key: string]: string | boolean;
}): { [key: string]: string } | undefined => {
  let actualValues: { [key: string]: string } | undefined;

  Object.keys(checkboxFieldsPage10).forEach(key => {
    if (value[key]) {
      checkboxFieldsPage10[key].forEach(fieldKey => {
        if (!actualValues) {
          actualValues = {};
        }
        actualValues[fieldKey] = (value[fieldKey] || "").toString().replace(/(\s)/g, "");
      });
    }
  });
  return actualValues;
};
