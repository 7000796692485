import { FC, useEffect, useMemo } from "react";
import { Col, Form, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, getSwitchesCount } from "@ni/common/utils";
import { ProductValue, TenantProductWizardResponse } from "@ni/sdk/models";

import { checkHeader, headerSwitchHandler } from "../../../utils";

import {
  AccountBillingFormNames,
  AccountBillingSwitchHeaders,
  checkboxFieldsPage34,
  switchHeader34,
} from "./page34.constants";

interface PctAccountBillingProps {
  currency: string;
  header?: string;
  form: FormInstance<FormValues>;
  formDisabled?: boolean;
  editMode?: boolean;
}

export const PctAccountBilling: FC<PctAccountBillingProps> = ({ currency, header, form, formDisabled, editMode }) => {
  const [wizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const productTransPresetValues = useMemo(() => {
    const pctProductValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues as ProductValue[];

    return {
      transCatContC: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("C"),
      transDomForTrue: getFormValueFromProductValues(pctProductValues, "trans-lim-dom-for", "boolean") === true,
      transCatContR: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("R"),
      transCatContT: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("T"),
    };
  }, [wizardResponse]);

  useEffect(() => {
    Object.keys(checkboxFieldsPage34).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const item of checkboxFieldsPage34[key]) {
        if (form.getFieldValue(item)) {
          form.setFieldsValue({
            [key]: true,
          });
          break;
        }
      }
    });

    checkHeader(AccountBillingSwitchHeaders.allCashEnable, switchHeader34, form);
    checkHeader(AccountBillingSwitchHeaders.allRetailEnable, switchHeader34, form);
    checkHeader(AccountBillingSwitchHeaders.allTotalEnable, switchHeader34, form);
  });

  const isShowForeign = useMemo(
    () => productTransPresetValues?.transDomForTrue || editMode,
    [productTransPresetValues?.transDomForTrue, editMode],
  );

  const switchShownCash = {
    all: true,
    foreign: isShowForeign,
  };

  const switchShownRetail = {
    all: true,
    foreign: isShowForeign,
  };

  const cashSwitchesNotOne = getSwitchesCount(switchShownCash) !== 1;
  const retailSwitchesNotOne = getSwitchesCount(switchShownRetail) !== 1;

  return (
    <>
      {header && <div className="text-blue-h3">{header}</div>}
      {(productTransPresetValues?.transCatContC || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item
              valuePropName="checked"
              name={AccountBillingSwitchHeaders.allCashEnable}
              className="header-switch"
            >
              <Switch
                onClick={value =>
                  headerSwitchHandler(AccountBillingSwitchHeaders.allCashEnable, switchHeader34, value, form)
                }
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Cash"
                code={AccountBillingSwitchHeaders.allCashEnable}
                tooltipProps={{ title: "Turns off all cash limits." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={AccountBillingFormNames.cashEnable} className="col-switch">
                  {cashSwitchesNotOne && (
                    <Switch
                      onClick={() => checkHeader(AccountBillingSwitchHeaders.allCashEnable, switchHeader34, form)}
                    />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code={AccountBillingFormNames.cashEnable}
                    tooltipProps={{
                      title:
                        "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.cashEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.cashMaxNr}>
                        <NetworkForm.Number
                          min={0}
                          stringMode={true}
                          disabled={!form.getFieldValue(AccountBillingFormNames.cashEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.cashEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.cashMaxAm}>
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue(AccountBillingFormNames.cashEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.cashEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.cashMaxSingle}>
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue(AccountBillingFormNames.cashEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            {isShowForeign && (
              <Row>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    name={AccountBillingFormNames.cashForEnable}
                    className="col-switch"
                  >
                    {cashSwitchesNotOne && (
                      <Switch
                        onClick={() => checkHeader(AccountBillingSwitchHeaders.allCashEnable, switchHeader34, form)}
                      />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="Foreign"
                      code={AccountBillingFormNames.cashForEnable}
                      tooltipProps={{
                        title:
                          "This limiter counts all foreign cash transactions. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={[AccountBillingFormNames.cashForEnable]}>
                    {() => {
                      return (
                        <Form.Item name={AccountBillingFormNames.cashForMaxNr}>
                          <NetworkForm.Number
                            min={0}
                            stringMode={true}
                            disabled={!form.getFieldValue(AccountBillingFormNames.cashForEnable) || formDisabled}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={[AccountBillingFormNames.cashForEnable]}>
                    {() => {
                      return (
                        <Form.Item name={AccountBillingFormNames.cashForMaxAm}>
                          <NetworkForm.Number
                            addonAfter={currency}
                            stringMode={true}
                            min={0}
                            disabled={!form.getFieldValue(AccountBillingFormNames.cashForEnable) || formDisabled}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={[AccountBillingFormNames.cashForEnable]}>
                    {() => {
                      return (
                        <Form.Item name={AccountBillingFormNames.cashForMaxSingle}>
                          <NetworkForm.Number
                            addonAfter={currency}
                            stringMode={true}
                            min={0}
                            disabled={!form.getFieldValue(AccountBillingFormNames.cashForEnable) || formDisabled}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContR || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item
              valuePropName="checked"
              name={AccountBillingSwitchHeaders.allRetailEnable}
              className="header-switch"
            >
              <Switch
                onClick={value =>
                  headerSwitchHandler(AccountBillingSwitchHeaders.allRetailEnable, switchHeader34, value, form)
                }
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Retail"
                code={AccountBillingSwitchHeaders.allRetailEnable}
                tooltipProps={{ title: "Turns off all retail transactions." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={AccountBillingFormNames.retailEnable} className="col-switch">
                  {retailSwitchesNotOne && (
                    <Switch
                      onClick={() => checkHeader(AccountBillingSwitchHeaders.allRetailEnable, switchHeader34, form)}
                    />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code={AccountBillingFormNames.retailEnable}
                    tooltipProps={{
                      title:
                        "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.retailEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.retailMaxNr}>
                        <NetworkForm.Number
                          min={0}
                          stringMode={true}
                          disabled={!form.getFieldValue(AccountBillingFormNames.retailEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.retailEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.retailMaxAm}>
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue(AccountBillingFormNames.retailEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.retailEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.retailMaxSingle}>
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue(AccountBillingFormNames.retailEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            {isShowForeign && (
              <Row>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    name={AccountBillingFormNames.retailForEnable}
                    className="col-switch"
                  >
                    {retailSwitchesNotOne && (
                      <Switch
                        onClick={() => checkHeader(AccountBillingSwitchHeaders.allRetailEnable, switchHeader34, form)}
                      />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="Foreign"
                      code={AccountBillingFormNames.retailForEnable}
                      tooltipProps={{
                        title:
                          "This limiter counts foreign retail transactions. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={[AccountBillingFormNames.retailForEnable]}>
                    {() => {
                      return (
                        <Form.Item name={AccountBillingFormNames.retailForMaxNr}>
                          <NetworkForm.Number
                            min={0}
                            stringMode={true}
                            disabled={!form.getFieldValue(AccountBillingFormNames.retailForEnable) || formDisabled}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={[AccountBillingFormNames.retailForEnable]}>
                    {() => {
                      return (
                        <Form.Item name={AccountBillingFormNames.retailForMaxAm}>
                          <NetworkForm.Number
                            addonAfter={currency}
                            stringMode={true}
                            min={0}
                            disabled={!form.getFieldValue(AccountBillingFormNames.retailForEnable) || formDisabled}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={[AccountBillingFormNames.retailForEnable]}>
                    {() => {
                      return (
                        <Form.Item name={AccountBillingFormNames.retailForMaxSingle}>
                          <NetworkForm.Number
                            addonAfter={currency}
                            stringMode={true}
                            min={0}
                            disabled={!form.getFieldValue(AccountBillingFormNames.retailForEnable) || formDisabled}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContT || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item
              valuePropName="checked"
              name={AccountBillingSwitchHeaders.allTotalEnable}
              className="header-switch"
            >
              <Switch
                onClick={value =>
                  headerSwitchHandler(AccountBillingSwitchHeaders.allTotalEnable, switchHeader34, value, form)
                }
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Total"
                code={AccountBillingSwitchHeaders.allTotalEnable}
                tooltipProps={{
                  title:
                    "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={AccountBillingFormNames.totalEnable} className="col-switch" />
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code={AccountBillingFormNames.totalEnable}
                    tooltipProps={{
                      title:
                        "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.totalEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.totalMaxNr}>
                        <NetworkForm.Number
                          min={0}
                          stringMode={true}
                          disabled={!form.getFieldValue(AccountBillingFormNames.totalEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.totalEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.totalMaxAm}>
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue(AccountBillingFormNames.totalEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[AccountBillingFormNames.totalEnable]}>
                  {() => {
                    return (
                      <Form.Item name={AccountBillingFormNames.totalMaxSingle}>
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue(AccountBillingFormNames.totalEnable) || formDisabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

PctAccountBilling.defaultProps = {
  header: undefined,
  formDisabled: true,
  editMode: false,
};

export const getAccountBillingLimitsPreparedValues = (value: {
  [key: string]: string | boolean;
}): { [key: string]: string } | undefined => {
  let actualValues: { [key: string]: string } | undefined;

  Object.keys(checkboxFieldsPage34).forEach(key => {
    if (value[key]) {
      checkboxFieldsPage34[key].forEach(fieldKey => {
        if (!actualValues) {
          actualValues = {};
        }
        actualValues[fieldKey] = (value[fieldKey] || "").toString().replace(/(\s)/g, "");
      });
    }
  });
  return actualValues;
};
