import { FC, useMemo } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { NIC_CCY } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, FormulaTextArea, RadioGroup } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import {
  balanceComponentsOptions,
  mtpMinimumAmountOptions,
  mtpModeOptions,
  mtpPercentageOptions,
  mtpResultOptions,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const MinimumToPayFormulaPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const mtpPercantage = Form.useWatch<string>(mtpPercentageOptions.code, form)?.toString() ?? "0";
  const balance = Form.useWatch<string>(balanceComponentsOptions.code, form);
  const mode = Form.useWatch<string>(mtpModeOptions.code, form);

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: [
        balanceComponentsOptions.code,
        mtpModeOptions.code,
        mtpMinimumAmountOptions.code,
        mtpPercentageOptions.code,
      ],
    },
    allowParse: false,
  });

  const { currencyType } = useMemo(
    () => ({
      currencyType: wizardResponse.product?.productValues?.find(x => x.fieldCode === NIC_CCY)?.value,
    }),
    [wizardResponse.product?.productValues],
  );

  const isIslamicCredit =
    getFormValueFromProductValues(wizardResponse.product?.productValues, "credit-type") === "Islamic";

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Minimum to Pay Formula"
      pageSubtitle="MTP amount is calculated from the balance components, which are included as MTP percentage% or fully (100%)."
      size="md"
      formSize="md"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={mtpPercentageOptions.code}
        label={<FormItemLabel label={mtpPercentageOptions.name} code={mtpPercentageOptions.code} />}
        initialValue={mtpPercentageOptions.default}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: mtpPercentageOptions.tooltip,
        }}
        rules={[{ required: true, message: "MTP percentage is required!" }]}
      >
        <NetworkForm.Number
          addonAfter="%"
          min={mtpPercentageOptions.min}
          max={mtpPercentageOptions.max}
          precision={mtpPercentageOptions.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={mtpMinimumAmountOptions.code}
        label={<FormItemLabel label={mtpMinimumAmountOptions.name} code={mtpMinimumAmountOptions.code} />}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: mtpMinimumAmountOptions.tooltip,
        }}
      >
        <NetworkForm.Number addonAfter={currencyType} min={0} precision={mtpMinimumAmountOptions.decimalsNumber} />
      </Form.Item>
      <Form.Item
        name={balanceComponentsOptions.code}
        label={<FormItemLabel label={balanceComponentsOptions.name} code={balanceComponentsOptions.code} />}
        initialValue={balanceComponentsOptions.default}
      >
        <RadioGroup
          radioList={balanceComponentsOptions.options}
          initialValue={balanceComponentsOptions.default as string}
        />
      </Form.Item>

      <Form.Item
        name={mtpModeOptions.code}
        label={<FormItemLabel label={mtpModeOptions.name} code={mtpModeOptions.code} />}
        initialValue={mtpModeOptions.default}
      >
        <RadioGroup radioList={mtpModeOptions.options} initialValue={mtpModeOptions.default as string} />
      </Form.Item>

      <Form.Item
        name={mtpResultOptions.code}
        label={<FormItemLabel label={mtpResultOptions.name} code={mtpResultOptions.code} />}
      >
        <FormulaTextArea balance={balance} mode={mode} percentage={mtpPercantage} isIslamicCredit={isIslamicCredit} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
