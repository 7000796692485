import { FC } from "react";
import { Form, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { usePct } from "@ni/product-settings";

import {
  canChargeWhenIncreasingPermanentLimit,
  canChargeWhenTemporaryExceedsPermanent,
  permanentLimitIncreaseFeeAmount,
  temporaryLimitFeeAmount,
} from "./CreditLimitModificationFees.constants";

export const CreditLimitModificationFeesPage: FC = () => {
  const { productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [form] = Form.useForm<FormValues>();

  const { productCurrency: currencyType } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: pct.pctProductValues ?? [],
    keys: {
      strings: [
        canChargeWhenIncreasingPermanentLimit.code,
        canChargeWhenTemporaryExceedsPermanent.code,
        permanentLimitIncreaseFeeAmount.code,
        temporaryLimitFeeAmount.code,
      ],
    },
    allowParse: true,
  });

  const hasChargeWhenTemporaryExceed = Form.useWatch<boolean>(canChargeWhenTemporaryExceedsPermanent.code, form);
  const hasChargeWhenIncreasingPermanent = Form.useWatch<boolean>(canChargeWhenIncreasingPermanentLimit.code, form);

  const onFinish = (values: FormValues) => {
    void onSavePct({
      [temporaryLimitFeeAmount.code]: "",
      [permanentLimitIncreaseFeeAmount.code]: "",
      ...values,
    } as FormValues);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Credit Limit Modification Fees"
      pageSubtitle="Credit limit modification fee can be charged when a client requests an increase or decrease in their permanent or temporary credit limit."
      size="md"
      formSize="full"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item
          name={canChargeWhenTemporaryExceedsPermanent.code}
          valuePropName="checked"
          initialValue={canChargeWhenTemporaryExceedsPermanent.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={canChargeWhenTemporaryExceedsPermanent.name}
          tooltipProps={{
            title: canChargeWhenTemporaryExceedsPermanent.tooltip,
          }}
        />
      </Space>

      {hasChargeWhenTemporaryExceed && (
        <Form.Item
          name={temporaryLimitFeeAmount.code}
          label={temporaryLimitFeeAmount.name}
          rules={[{ required: true, message: "Temporary Limit Fee Amount is required!" }]}
        >
          <NetworkForm.Number
            addonAfter={currencyType}
            min={temporaryLimitFeeAmount.min}
            precision={temporaryLimitFeeAmount.decimalsNumber}
          />
        </Form.Item>
      )}

      <Space direction="horizontal">
        <Form.Item
          name={canChargeWhenIncreasingPermanentLimit.code}
          valuePropName="checked"
          initialValue={canChargeWhenIncreasingPermanentLimit.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={canChargeWhenIncreasingPermanentLimit.name}
          tooltipProps={{
            title: canChargeWhenIncreasingPermanentLimit.tooltip,
          }}
        />
      </Space>

      {hasChargeWhenIncreasingPermanent && (
        <Form.Item
          name={permanentLimitIncreaseFeeAmount.code}
          label={permanentLimitIncreaseFeeAmount.name}
          rules={[{ required: true, message: "Permanent Limit Increase Fee Amount is required!" }]}
        >
          <NetworkForm.Number
            addonAfter={currencyType}
            min={permanentLimitIncreaseFeeAmount.min}
            precision={permanentLimitIncreaseFeeAmount.decimalsNumber}
          />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
