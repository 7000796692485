import { FC, useEffect, useState } from "react";
import { Form, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  CP_FEES_ENABLED,
  NIC_CP_NEW,
  NIC_CP_REISS,
  NIC_CP_RENEXP,
  NIC_CP_REPL,
  NIC_CP_RNEW,
} from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const CardProductionFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      [CP_FEES_ENABLED]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            CP_FEES_ENABLED,
            "boolean",
          )
        : true,
      [NIC_CP_NEW]: formDisabledFn(NIC_CP_NEW, ""),
      [NIC_CP_RNEW]: formDisabledFn(NIC_CP_RNEW, ""),
      [NIC_CP_REPL]: formDisabledFn(NIC_CP_REPL, "65.00"),
      [NIC_CP_RENEXP]: formDisabledFn(NIC_CP_RENEXP, ""),
      [NIC_CP_REISS]: formDisabledFn(NIC_CP_REISS, "65.00"),
    });
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const onFormChange = () => {
    if (form.getFieldValue("mem_fee_type") === "Y") {
      setIsEnabled(form.getFieldValue("nic-amf-acc-val").length > 0);
    } else {
      setIsEnabled(true);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues:
        String(form.getFieldValue(CP_FEES_ENABLED)) === "true"
          ? {
              [CP_FEES_ENABLED]: String(form.getFieldValue(CP_FEES_ENABLED)),

              [NIC_CP_NEW]: String(form.getFieldValue(NIC_CP_NEW)).replace(/ /g, ""),

              [NIC_CP_RNEW]: String(form.getFieldValue(NIC_CP_RNEW)).replace(/ /g, ""),

              [NIC_CP_REPL]: String(form.getFieldValue(NIC_CP_REPL)).replace(/ /g, ""),

              [NIC_CP_RENEXP]: String(form.getFieldValue(NIC_CP_RENEXP)).replace(/ /g, ""),

              [NIC_CP_REISS]: String(form.getFieldValue(NIC_CP_REISS)).replace(/ /g, ""),
            }
          : {
              [CP_FEES_ENABLED]: String(form.getFieldValue(CP_FEES_ENABLED)),
            },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isEnabled}
      pageTitle="Card Production Fees"
      pageSubtitle="System provides the feasibility to charge fixed fee amount when production event is triggered i.e.
      whenever card is produced, renewed, replaced, reissued a fixed amount of fee can be charged to
      cardholders. You can specify fee amount below."
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item valuePropName="checked" name={CP_FEES_ENABLED}>
          <Switch />
        </Form.Item>
        <TooltipInfo label="Enable Card production fees on your product" code={CP_FEES_ENABLED} tooltipProps={{}} />
      </Space>
      <Form.Item dependencies={[CP_FEES_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_CP_NEW}
              label={<FormItemLabel label="New card" code={NIC_CP_NEW} />}
              tooltip={{
                title: "New card number, card expire and PIN code (if applicable) is created in the system",
                icon: <QuestionCircleFilled />,
              }}
            >
              <NetworkForm.Number
                stringMode={true}
                precision={2}
                min={0}
                addonAfter={currency}
                disabled={!form.getFieldValue(CP_FEES_ENABLED) || formDisabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      {(getFormValueFromProductValues(wizardResponse.product?.productValues, "reiss-phys", "boolean") as boolean) && (
        <Form.Item dependencies={[CP_FEES_ENABLED]}>
          {() => {
            return (
              <Form.Item
                name={NIC_CP_RNEW}
                label={<FormItemLabel label="Physical plastic for existing virtual card" code={NIC_CP_RNEW} />}
                tooltip={{
                  title:
                    "Only physical plastic is produced for existing virtual card, all card credentials remain the same",
                  icon: <QuestionCircleFilled />,
                }}
              >
                <NetworkForm.Number
                  stringMode={true}
                  precision={2}
                  min={0}
                  addonAfter={currency}
                  disabled={!form.getFieldValue("cp-fees-enabled") || formDisabled}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      )}

      <Form.Item dependencies={[CP_FEES_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_CP_REPL}
              label={<FormItemLabel label="Replace card (new card number)" code={NIC_CP_REPL} />}
              tooltip={{
                title:
                  "New card number, CVV, card expire and PIN code (if applicable) is created in the system instead the existing one (i.e. due to card loss or stolen)",
                icon: <QuestionCircleFilled />,
              }}
            >
              <NetworkForm.Number
                stringMode={true}
                precision={2}
                min={0}
                addonAfter={currency}
                disabled={!form.getFieldValue("cp-fees-enabled") || formDisabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item dependencies={[CP_FEES_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_CP_RENEXP}
              label={<FormItemLabel label="Renew expired card (new expire date)" code={NIC_CP_RENEXP} />}
              tooltip={{
                title:
                  "Card expire date is recalculated according to the separate expiration date setup (check it in general card-related parameters of the Tenant settings)",
                icon: <QuestionCircleFilled />,
              }}
            >
              <NetworkForm.Number
                stringMode={true}
                precision={2}
                min={0}
                addonAfter={currency}
                disabled={!form.getFieldValue("cp-fees-enabled") || formDisabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item dependencies={[CP_FEES_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_CP_REISS}
              label={<FormItemLabel label="Reissue card (same card number)" code={NIC_CP_REISS} />}
              tooltip={{
                title:
                  "Card number remains the same, card expire date is recalculated according to the separate expiration date setup",
                icon: <QuestionCircleFilled />,
              }}
            >
              <NetworkForm.Number
                stringMode={true}
                precision={2}
                min={0}
                addonAfter={currency}
                disabled={!form.getFieldValue("cp-fees-enabled") || formDisabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </CustomFormWrapper>
  );
};
