import { FC, ReactElement, useEffect, useMemo } from "react";
import { Form, Radio, Select, Space, Switch } from "antd";
import { StoreValue } from "rc-field-form/lib/interface";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useProductSettings, useReduxState } from "@ni/common/hooks";
import { nicStmtFlagDefNewOptions, periodRadioOptions } from "@ni/common/mocks";
import { FormValues, SelectOption } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, getFormValueTenantValues } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

export const SmsNotification: FC = () => {
  const [form] = Form.useForm();
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const [tenant] = useReduxState<Tenant>("tenant", {});

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const balanceOwner = getFormValueFromProductValues(product.productValues, "balance-owner") as string;

  const nicStmtFlagDefNewSelectDefaultValue = () => {
    if (balanceOwner === "CMS") {
      return "E";
    }
    if (balanceOwner === "CBS") {
      return "O";
    }
    return null;
  };

  useEffect(() => {
    if (product?.id) {
      const nicSmsFlagDefNew =
        ((getFormValueFromProductValues(product.productValues, "nic-sms-flag-def-new") as string) || "true") === "true";

      const nicAutorenewSmsEventEdited = getFormValueFromProductValues(
        product.productValues,
        "nic-autorenew-sms-event-edited",
        "boolean",
      );
      console.log(getFormValueFromProductValues(product.productValues, "nic-autorenew-event-per"));

      const nicAutorenewEventPerType =
        (getFormValueFromProductValues(product.productValues, "nic-autorenew-event-per-type", "string") as string) ||
        periodRadioOptions[0].value;

      const nicAutorenewEventPer = nicAutorenewSmsEventEdited
        ? parseInt(
            (getFormValueFromProductValues(product.productValues, "nic-autorenew-event-per") as string) || "",
            10,
          )
        : parseInt(
            (getFormValueFromProductValues(product.productValues, "nic-card-subtype-autorenew-days") as string) || "",
            10,
          );

      const nicStmtFlagDefNewSet = getFormValueFromProductValues(
        product.productValues,
        "nic-stmt-flag-def-new",
      ) as string;

      form.setFieldsValue({
        "nic-sms-flag-def-new": nicSmsFlagDefNew,
        "nic-autorenew-event-per-type": nicAutorenewEventPerType,
        "nic-autorenew-event-per": nicAutorenewEventPer,
        ...(nicStmtFlagDefNewSet && { "nic-stmt-flag-def-new": nicStmtFlagDefNewSet }),
      });
    }
  }, [form, product?.id, product.productValues]);

  const periodOptions = periodRadioOptions;

  const relatedLinks = useMemo(() => {
    const links = [
      {
        label: "SMS Settings - Global",
        href: `/tenant/${tenantId}/sms-general-settings`,
      },
    ];

    if (getFormValueTenantValues(tenant, "enable-sms-glob") === "true") {
      links.push({
        label: "SMS Templates - Global",
        href: `/tenant/${tenantId}/sms-templates`,
      });
    }

    return links;
  }, [tenant, tenantId]);

  const getAutoClosePeriodOptions = (): ReactElement[] =>
    periodOptions.map(val => (
      <Radio key={val.value} value={val.value}>
        {val.label}
      </Radio>
    ));

  const onFinish = (values: FormValues) => {
    void onUpdateProduct({ "nic-autorenew-sms-event-edited": true, ...values });
  };

  const validateNicAutorenewEventPer = (value: StoreValue): Promise<string | void> => {
    if (value === null) {
      return Promise.resolve();
    }
    if (
      form.getFieldValue("nic-autorenew-event-per-type") === periodRadioOptions[0].value &&
      (value > 90 || value < 1)
    ) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Number of days must be between 1 and 90");
    }
    if (
      form.getFieldValue("nic-autorenew-event-per-type") === periodRadioOptions[1].value &&
      (value > 3 || value < 1)
    ) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Number of months must be between 1 and 3");
    }
    return Promise.resolve();
  };

  const nicStmtFlagDefNewSelect = () => (
    <Select>
      {nicStmtFlagDefNewOptions.map((value: SelectOption) => (
        <Select.Option key={value.value} value={value.value}>
          {value.label}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <CustomFormWrapper
      pageTitle="SMS Notification & Statement Generation"
      pageSubtitle="The system offers the capability to send SMS messages or generate account statements. To enable this feature for specific situations, corresponding flags must be activated at the product or account level, and SMS settings need to be configured within the system."
      form={form}
      submitHandler={onFinish}
      size="md"
      formSize="md"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      formProps={{
        initialValues: { "nic-stmt-flag-def-new": nicStmtFlagDefNewSelectDefaultValue() },
      }}
    >
      <Space direction="horizontal" size={8}>
        <Form.Item name="nic-sms-flag-def-new" valuePropName="checked">
          <Switch />
        </Form.Item>
        <TooltipInfo
          label="Enable SMS flag by default for all accounts from boarding"
          tooltipProps={{
            title:
              "Client with the product will be enrolled for SMS notification by default, but could opt out any time",
          }}
        />
      </Space>
      <Form.Item
        name="nic-autorenew-event-per-type"
        label="Period unit type for sending SMS about card auto-renewal (before expiry)"
        tooltip={{
          title: "Please visit the SMS Templates - Global page to modify the appropriate SMS template",
          icon: <QuestionCircleFilled />,
        }}
      >
        <Radio.Group>
          <Space direction="vertical">{getAutoClosePeriodOptions()}</Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="nic-autorenew-event-per"
        label="Period unit number for sending SMS about card auto-renewal (before expiry)"
        rules={[
          () => ({
            validator(_, value) {
              return validateNicAutorenewEventPer(value);
            },
          }),
        ]}
      >
        <NetworkForm.Number />
      </Form.Item>

      <Form.Item
        name="nic-stmt-flag-def-new"
        label="Set default Statement flag for the product"
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: "Client with the product will have this statement flag by default, but he could change it any time",
        }}
      >
        {nicStmtFlagDefNewSelect()}
      </Form.Item>
    </CustomFormWrapper>
  );
};
