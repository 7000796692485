import { FC, useMemo } from "react";
import { Form, Select, Space, Switch } from "antd";
import { StoreValue } from "rc-field-form/lib/interface";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useGetPerso, useHydrateForm, useProductSettings, useReduxState } from "@ni/common/hooks";
import { nicCardCarrAddressLevPrim, nicCardCarrAddressLevSuppl } from "@ni/common/mocks";
import { FormValues, SelectOption } from "@ni/common/types";
import { Alert, CustomFormWrapper, FormItemLabel, NetworkForm, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

import {
  NIC_CARD_CARR_ADDRESS_LEV_PRIM,
  NIC_CARD_CARR_ADDRESS_LEV_SUPPL,
  NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
  NIC_CARD_SUBTYPE_EXP_MONTHS,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
  NIC_PREEMB_AUTO_ACTIVATE,
  REISS_PHYS,
  SDE,
} from "../../constants";

import {
  cardPersonalizationOptions,
  cardTypeOptions,
  OTHER_INFO_TEXT,
  persoSelectValue,
  REISS_PHYS_LABEL,
  SDE_INFO,
  SDE_LABEL,
  SDE_TOOLTIP,
} from "./constants";

import styles from "./styles.module.scss";

const PERSO_SUBSTANCE = "PERSO_SUBSTANCE";

const stringsKeys = [
  cardTypeOptions.code,
  cardPersonalizationOptions.code,
  NIC_CARD_SUBTYPE_EXP_MONTHS,
  NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
  NIC_CARD_CARR_ADDRESS_LEV_PRIM,
  NIC_CARD_CARR_ADDRESS_LEV_SUPPL,
  REISS_PHYS,
  NIC_PREEMB_AUTO_ACTIVATE,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
  SDE,
  PERSO_SUBSTANCE,
];

export const CardAndPinMaintenance: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();
  const [tenant] = useReduxState<Tenant>("tenant", {});

  const { persoList } = useGetPerso();

  const cardPersonalization = Form.useWatch<string>(cardPersonalizationOptions.code, form);
  const isVirtPhysV = Form.useWatch<string>(cardTypeOptions.code, form) === cardTypeOptions.options[0].value;
  const isSdeTrue = Form.useWatch<boolean>(SDE, form) === true;
  const isPersoNotO =
    Form.useWatch<string>(cardPersonalizationOptions.code, form) !== cardPersonalizationOptions.options[1].value;

  const relatedLinks = [
    {
      href: `/tenant/${tenant.id}/card-and-pin-maintenance`,
      label: "Card and PIN Maintenance - Global",
    },
  ];

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const persoSubstanceValue = useMemo(
    () => product.productValues?.find(x => x.fieldCode === cardPersonalizationOptions.code)?.value,
    [product.productValues],
  );

  useHydrateForm(
    {
      form,
      entityFields: product?.productValues
        ? [
            ...product.productValues,
            ...(persoSubstanceValue === cardPersonalizationOptions.options[1].value
              ? []
              : [
                  {
                    fieldCode: PERSO_SUBSTANCE,
                    value: persoSubstanceValue,
                  },
                  {
                    fieldCode: cardPersonalizationOptions.code,
                    value: persoSelectValue,
                  },
                ]),
          ]
        : [],
      keys: {
        strings: stringsKeys,
      },
    },
    [product],
  );

  const onValuesChange = (values: Partial<FormValues>) => {
    if (values[cardTypeOptions.code] === "B" && values[cardPersonalizationOptions.code] === undefined) {
      form.setFieldValue(cardPersonalizationOptions.code, "I");
    }
  };

  const onSubmit = (values: FormValues) => {
    if (values[PERSO_SUBSTANCE]) {
      values[cardPersonalizationOptions.code] = values[PERSO_SUBSTANCE];
      delete values[PERSO_SUBSTANCE];
    }

    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && product?.productValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });

    void onUpdateProduct(values);
  };

  const validateNicCardSubtypeAutorenewDays = (value: StoreValue): Promise<string | void> => {
    if (value && (value < 0 || value > 90)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>(" Number of days must be equals to or lower than 90.");
    }
    return Promise.resolve();
  };

  const nicCardCarrAddressLevPrimSelect = () => (
    <Select>
      {nicCardCarrAddressLevPrim.map((value: SelectOption) => (
        <Select.Option key={value.value} value={value.value}>
          {value.label}
        </Select.Option>
      ))}
    </Select>
  );

  const nicCardCarrAddressLevSupplSelect = () => (
    <Select>
      {nicCardCarrAddressLevSuppl.map((value: SelectOption) => (
        <Select.Option key={value.value} value={value.value}>
          {value.label}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card and PIN Maintenance"
      submitHandler={onSubmit}
      onValuesChange={onValuesChange}
      size="md"
      formSize="md"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      gap={24}
    >
      <Form.Item name={cardTypeOptions.code} label={cardTypeOptions.name}>
        <RadioGroup radioList={cardTypeOptions.options} />
      </Form.Item>

      {!isVirtPhysV && (
        <>
          <Form.Item
            name={cardPersonalizationOptions.code}
            label={cardPersonalizationOptions.name}
            initialValue={cardPersonalizationOptions.options[0].value}
          >
            <RadioGroup radioList={cardPersonalizationOptions.options} />
          </Form.Item>
          {cardPersonalization === cardPersonalizationOptions.options[1].value ? (
            <Alert>{OTHER_INFO_TEXT}</Alert>
          ) : cardPersonalization ? (
            <Form.Item
              name={PERSO_SUBSTANCE}
              label={
                <FormItemLabel
                  label="Network International partners (Perso Bureau)"
                  code={cardPersonalizationOptions.code}
                />
              }
              rules={[
                {
                  required: true,
                  message: "Network International partners is required.",
                },
                {
                  validator: (_, value) => {
                    if (value === persoSelectValue) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: "Network International partners is required.",
                },
              ]}
              initialValue="IDEMIA"
            >
              <Select>
                {persoList.map(listEntry => (
                  <Select.Option key={listEntry.value} value={listEntry.value}>
                    <FormItemLabel label={listEntry.displayValue} code={listEntry.value} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
        </>
      )}

      <Form.Item
        name={NIC_CARD_SUBTYPE_EXP_MONTHS}
        label="Number of months from card issuing till card expirations"
        className={styles["product-details-form-input"]}
        rules={[
          { required: true, message: "Number of months is required!" },
          { type: "number", min: 12, message: " Number of months must be equal to or larger than 12." },
          { type: "number", max: 60, message: " Number of months must be equals to or lower than 60." },
        ]}
      >
        <NetworkForm.Number placeholder="Number of months" />
      </Form.Item>

      <Form.Item
        name={NIC_CARD_SUBTYPE_AUTORENEW_DAYS}
        label="Number of days before card expiration to initiate card renewal process"
        className={styles["product-details-form-input"]}
        rules={[
          () => ({
            validator(_, value) {
              return validateNicCardSubtypeAutorenewDays(value);
            },
          }),
        ]}
      >
        <NetworkForm.Number placeholder="Number of days" />
      </Form.Item>

      <Form.Item
        className={styles["product-details-form-input"]}
        name={NIC_CARD_CARR_ADDRESS_LEV_PRIM}
        label="Deliver primary cards and PINs"
        initialValue="A"
      >
        {nicCardCarrAddressLevPrimSelect()}
      </Form.Item>

      <Form.Item
        className={styles["product-details-form-input"]}
        name={NIC_CARD_CARR_ADDRESS_LEV_SUPPL}
        label="Deliver supplementary cards and PINs"
        tooltip={
          tenant.countryCode === "SA"
            ? {
                icon: <QuestionCircleFilled />,
                title:
                  "According to SAMA requirements Cards and PINs must only be delivered" +
                  " to the primary account holder in Case of prepaid supplementary cardholder",
              }
            : null
        }
        initialValue={tenant?.countryCode === "SA" ? "A" : "C"}
      >
        {nicCardCarrAddressLevSupplSelect()}
      </Form.Item>

      <Space direction="vertical" size={16}>
        {!isVirtPhysV && (
          <Space>
            <Form.Item valuePropName="checked" name={REISS_PHYS} className="m-b-0">
              <Switch />
            </Form.Item>
            {REISS_PHYS_LABEL}
          </Space>
        )}

        <Space direction="horizontal">
          <Form.Item name={NIC_PREEMB_AUTO_ACTIVATE} valuePropName="checked">
            <Switch />
          </Form.Item>
          Virtual cards auto-activation
        </Space>

        <Space direction="horizontal">
          <Form.Item
            name={NIC_CARD_SUBTYPE_PINSET_ALLOWED}
            valuePropName="checked"
            rules={[{ required: true, message: "Pin Set is required!" }]}
          >
            <Switch />
          </Form.Item>
          PIN set is allowed
        </Space>

        <Space direction="horizontal">
          <Form.Item
            name={NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED}
            valuePropName="checked"
            rules={[{ required: true, message: "Change PIN is required!" }]}
          >
            <Switch />
          </Form.Item>

          <TooltipInfo
            label="Change PIN allowed for locked plastics"
            tooltipProps={{ title: "This allows your customer to change card PIN prior to activating it" }}
          />
        </Space>

        {!isVirtPhysV && isPersoNotO && (
          <>
            <Space>
              <Form.Item valuePropName="checked" name={SDE} className="m-b-0">
                <Switch />
              </Form.Item>
              <TooltipInfo label={SDE_LABEL} tooltipProps={{ title: SDE_TOOLTIP }} />
            </Space>
            {isSdeTrue && <Alert>{SDE_INFO}</Alert>}
          </>
        )}
      </Space>
    </CustomFormWrapper>
  );
};
