import { FC, useMemo } from "react";
import { Form, Select, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  ALPHA2_COUNTRIES_LIST,
  CHECKBOX_GROUP_ELIGIBILITY_CRITERIA,
  LTY_CHECK_RULES,
  LTY_PREF_MAX,
  LTY_PREF_MIN,
  LTY_RULE_CNTR,
  LTY_RULE_CNTR_LIST,
  LTY_RULE_MCC,
  LTY_RULE_MCC_LIST,
  LTY_RULE_MERCH,
  LTY_RULE_MERCH_LIST,
  LTY_RULE_TERM_LIST,
  LTY_RULES_CRITERIA,
  MCC_LIST,
  RADIO_GROUP,
  RADIO_GROUP_ELIGIBLE,
  VALIDATION_MIN_MAX_MESSAGE,
} from "@ni/common/constants";
import { useHydrateForm, useParamsList } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { Checkbox, CustomFormWrapper, NetworkForm, RadioGroup, Tabs } from "@ni/common/ui";
import { removeGapsFromStringNumeric, validateNumberInput } from "@ni/common/utils";

import { LoyaltyProgramPagesProps } from "../../types";

import { TAB_TITLES } from "./constants";

type KeyTab = "AMN" | "CNTR" | "TERM" | "MERCH" | "MCC";

interface TransactionEligibilityProps extends LoyaltyProgramPagesProps {
  productCurrency: string;
}

const stringsKeys = [LTY_PREF_MIN, LTY_PREF_MAX, LTY_CHECK_RULES, LTY_RULE_MERCH, LTY_RULE_MCC, LTY_RULE_CNTR];
const listsKeys = [LTY_RULES_CRITERIA, LTY_RULE_TERM_LIST, LTY_RULE_MERCH_LIST, LTY_RULE_MCC_LIST, LTY_RULE_CNTR_LIST];

const MIN_MAX_VALIDATION_MESSAGE = "Please fill min or max amount";

export const TransactionEligibility: FC<TransactionEligibilityProps> = ({
  loyaltyProgram,
  productCurrency,
  editLoyaltyProgram,
}) => {
  const [form] = Form.useForm<FormValues>();
  const {
    params: { tab },
  } = useParamsList(["tab"]);

  const initialValues = useHydrateForm({
    form,
    entityFields: loyaltyProgram?.programPctValues ?? [],
    keys: {
      strings: stringsKeys,
      lists: listsKeys,
    },
  });

  const isSpecificRetail = Form.useWatch<string>(LTY_CHECK_RULES, form) === "Y";
  const selectedCriteria = Form.useWatch<string[]>(LTY_RULES_CRITERIA, form);

  const pagesList = useMemo(() => {
    return [
      {
        key: "AMN" as KeyTab,
        label: <div title="Transaction amount">Transaction amount</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["AMN"]}</p>
            <Space direction="horizontal">
              <Switch
                checked={selectedCriteria?.includes("AMN")}
                onChange={isChecked => {
                  form.setFieldValue(
                    LTY_RULES_CRITERIA,
                    isChecked ? [...(selectedCriteria ?? []), "AMN"] : selectedCriteria.filter(x => x !== "AMN"),
                  );

                  if (!isChecked) {
                    form.setFieldsValue({ [LTY_PREF_MIN]: "", [LTY_PREF_MAX]: "" });
                  }
                }}
              />
              Enable Transaction amount criteria
            </Space>
            {selectedCriteria?.includes("AMN") && (
              <>
                <Form.Item
                  name={LTY_PREF_MIN}
                  label="Min amount"
                  className="w-p-50"
                  rules={[
                    () => ({
                      validator(_, value: string) {
                        const maxValue = form.getFieldValue(LTY_PREF_MAX) as string;
                        if (maxValue) {
                          if (+removeGapsFromStringNumeric(maxValue) < +removeGapsFromStringNumeric(value)) {
                            return Promise.reject(new Error(VALIDATION_MIN_MAX_MESSAGE));
                          }

                          return Promise.resolve();
                        }

                        return validateNumberInput(parseInt(value, 10), true, MIN_MAX_VALIDATION_MESSAGE);
                      },
                    }),
                  ]}
                >
                  <NetworkForm.Number min={0} precision={2} addonAfter={productCurrency} />
                </Form.Item>

                <Form.Item
                  name={LTY_PREF_MAX}
                  label="Max amount"
                  className="w-p-50"
                  rules={[
                    () => ({
                      validator(_, value: string) {
                        const minValue = form.getFieldValue(LTY_PREF_MIN) as string;
                        if (minValue) {
                          if (+removeGapsFromStringNumeric(minValue) > +removeGapsFromStringNumeric(value)) {
                            return Promise.reject(new Error(VALIDATION_MIN_MAX_MESSAGE));
                          }
                          return Promise.resolve();
                        }

                        return validateNumberInput(parseInt(value, 10), true, MIN_MAX_VALIDATION_MESSAGE);
                      },
                    }),
                  ]}
                >
                  <NetworkForm.Number min={0} precision={2} addonAfter={productCurrency} />
                </Form.Item>
              </>
            )}
          </Space>
        ),
        fields: [LTY_PREF_MIN, LTY_PREF_MAX],
      },
      {
        key: "CNTR" as KeyTab,
        label: <div title="Country">Country</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["CNTR"]}</p>
            <Space direction="horizontal">
              <Switch
                checked={selectedCriteria?.includes("CNTR")}
                onChange={isChecked => {
                  form.setFieldValue(
                    LTY_RULES_CRITERIA,
                    isChecked ? [...(selectedCriteria ?? []), "CNTR"] : selectedCriteria.filter(x => x !== "CNTR"),
                  );

                  if (!isChecked) {
                    form.setFieldsValue({ [LTY_RULE_CNTR]: "", [LTY_RULE_CNTR_LIST]: "" });
                  }
                }}
              />
              Enable country criteria
            </Space>
            {selectedCriteria?.includes("CNTR") && (
              <>
                <Form.Item name={LTY_RULE_CNTR} label="Select countries" initialValue={RADIO_GROUP[0].value}>
                  <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
                </Form.Item>
                <Form.Item
                  name={LTY_RULE_CNTR_LIST}
                  label="Country list"
                  rules={[
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out country list."));
                      },
                    },
                  ]}
                  className="w-p-50"
                >
                  <Select mode="multiple" optionFilterProp="children">
                    {ALPHA2_COUNTRIES_LIST.map(country => (
                      <Select.Option key={country.code} value={country.code}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </Space>
        ),
        fields: [LTY_RULE_CNTR, LTY_RULE_CNTR_LIST],
      },
      {
        key: "MCC" as KeyTab,
        label: <div title="erchant Categor">Merchant Category</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["MCC"]}</p>
            <Space direction="horizontal">
              <Switch
                checked={selectedCriteria?.includes("MCC")}
                onChange={isChecked => {
                  form.setFieldValue(
                    LTY_RULES_CRITERIA,
                    isChecked ? [...(selectedCriteria ?? []), "MCC"] : selectedCriteria.filter(x => x !== "MCC"),
                  );

                  if (!isChecked) {
                    form.setFieldsValue({ [LTY_RULE_MCC]: "", [LTY_RULE_MCC_LIST]: "" });
                  }
                }}
              />
              Enable merchant category criteria
            </Space>
            {selectedCriteria?.includes("MCC") && (
              <>
                <Form.Item
                  name={LTY_RULE_MCC}
                  label="Select merchant category code (MCC)"
                  initialValue={RADIO_GROUP[0].value}
                >
                  <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
                </Form.Item>
                <Form.Item
                  name={LTY_RULE_MCC_LIST}
                  label="Merchant category code list"
                  rules={[
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out merchant category code (MCC) list."));
                      },
                    },
                  ]}
                  className="w-p-50"
                >
                  <Select mode="multiple" optionFilterProp="children">
                    {MCC_LIST.map(mcc => (
                      <Select.Option key={mcc.key} value={mcc.key}>
                        {mcc.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </Space>
        ),
        fields: [LTY_RULE_MCC, LTY_RULE_MCC_LIST],
      },
      {
        key: "MERCH" as KeyTab,
        label: <div title="Merchant ID">Merchant ID</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["MERCH"]}</p>
            <Space direction="horizontal">
              <Switch
                checked={selectedCriteria?.includes("MERCH")}
                onChange={isChecked => {
                  form.setFieldValue(
                    LTY_RULES_CRITERIA,
                    isChecked ? [...(selectedCriteria ?? []), "MERCH"] : selectedCriteria.filter(x => x !== "MERCH"),
                  );

                  if (!isChecked) {
                    form.setFieldsValue({ [LTY_RULE_MERCH]: "", [LTY_RULE_MERCH_LIST]: "" });
                  }
                }}
              />
              Enable merchant id criteria
            </Space>
            {selectedCriteria?.includes("MERCH") && (
              <>
                <Form.Item name={LTY_RULE_MERCH} label="Select merchants" initialValue={RADIO_GROUP[0].value}>
                  <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
                </Form.Item>
                <Form.Item
                  name={LTY_RULE_MERCH_LIST}
                  label="Specify merchant ID list"
                  rules={[
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out Id list."));
                      },
                    },
                  ]}
                  className="w-p-50"
                >
                  <Select mode="tags" />
                </Form.Item>
              </>
            )}
          </Space>
        ),
        fields: [LTY_RULE_MERCH, LTY_RULE_MERCH_LIST],
      },
      {
        key: "TERM" as KeyTab,
        label: <div title="Terminal ID">Terminal ID</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["TERM"]}</p>
            <Space direction="horizontal">
              <Switch
                checked={selectedCriteria?.includes("TERM")}
                onChange={isChecked => {
                  form.setFieldValue(
                    LTY_RULES_CRITERIA,
                    isChecked ? [...(selectedCriteria ?? []), "TERM"] : selectedCriteria.filter(x => x !== "TERM"),
                  );

                  if (!isChecked) {
                    form.setFieldsValue({ [LTY_RULE_TERM_LIST]: "" });
                  }
                }}
              />
              Enable terminal id criteria
            </Space>
            {selectedCriteria?.includes("TERM") && (
              <Form.Item
                name={LTY_RULE_TERM_LIST}
                label="Specify merchant terminal ID list"
                rules={[
                  {
                    required: true,
                    validator: (_, value: string[]) => {
                      if (value && value.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please fill out Id list."));
                    },
                  },
                ]}
                className="w-p-50"
              >
                <Select mode="tags" />
              </Form.Item>
            )}
          </Space>
        ),
        fields: [LTY_RULE_TERM_LIST],
      },
    ];
  }, [form, productCurrency, selectedCriteria]);

  const onFinish = (values: FormValues) => {
    const stringifiedValues = { ...values };

    let fields: string[] = [];

    if (isSpecificRetail) {
      fields = pagesList.find(page => page.key === tab)?.fields ?? [];
    }

    if (!isSpecificRetail) {
      fields = [...stringsKeys, ...listsKeys];
    }

    fields.forEach(key => {
      if (!stringifiedValues[key] && loyaltyProgram?.programPctValues?.find(item => item.code === key)) {
        stringifiedValues[key] = "";
      } else if (Array.isArray(stringifiedValues[key])) {
        stringifiedValues[key] = stringifiedValues[key].join(",");
      }
    });

    editLoyaltyProgram(stringifiedValues);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Transaction Eligibility"
      pageSubtitle="Specify transaction eligibility to participate in loyalty program:"
      size="md"
      level="pct-loyalty"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <Form.Item name={LTY_CHECK_RULES} label="Transaction eligibility mode" initialValue="N">
        <RadioGroup radioList={RADIO_GROUP_ELIGIBLE} initialValue="N" />
      </Form.Item>

      {isSpecificRetail && (
        <div>
          <Form.Item
            name={LTY_RULES_CRITERIA}
            label="Eligibility criteria"
            tooltip={{
              title: "You can specify details for selected criteria on upcoming pages",
              icon: <QuestionCircleFilled />,
            }}
            rules={[{ required: true, message: "Select at least one criteria" }]}
            hidden={true}
          >
            <Checkbox className="m-t-16" checkboxes={CHECKBOX_GROUP_ELIGIBILITY_CRITERIA} />
          </Form.Item>
          <div className="w-p-150">
            <Tabs
              pagesList={pagesList}
              isCheckEnabled={true}
              form={form}
              initialValues={initialValues}
              discardAfterChangeTab={false}
              excludedFieldsListFromCheck={[LTY_CHECK_RULES]}
              onSave={onFinish}
            />
          </div>
        </div>
      )}
    </CustomFormWrapper>
  );
};
