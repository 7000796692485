import { FC, useEffect, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import {
  NIC_CARD_SUBTYPE_ABU_ACTIVE,
  NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
  NIC_CARD_SUBTYPE_EXP_MONTHS,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
  NIC_CARD_SUBTYPE_TOKEN_MADA_USED,
  NIC_CARD_SUBTYPE_TOKEN_USED,
  NIC_MADA_USE,
} from "@ni/common/constants";
import { useFormButtonDisabled, useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormContent, NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const FinishCardProductionPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form, initialState: false });

  const minExpireLength = useMemo(() => {
    return wizardResponse.tenant?.countryCode === "SA" ? 36 : 12;
  }, [wizardResponse.tenant?.countryCode]);

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: [
        NIC_CARD_SUBTYPE_EXP_MONTHS,
        NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
        NIC_CARD_SUBTYPE_PINSET_ALLOWED,
        NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
        NIC_CARD_SUBTYPE_ABU_ACTIVE,
        NIC_CARD_SUBTYPE_TOKEN_USED,
      ],
    },
    allowParse: false,
  });

  useEffect(() => {
    form.setFieldsValue({
      ...((getFormValueFromProductValues(
        wizardResponse.product?.productValues,
        NIC_MADA_USE,
        "boolean",
      ) as boolean) && {
        [NIC_CARD_SUBTYPE_TOKEN_MADA_USED]: getFormValueFromProductValues(
          wizardResponse.product?.productValues,
          NIC_MADA_USE,
        ) as string,
      }),
    });
  }, [form, formDisabled, wizardResponse.product?.productValues]);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: { ...(values as { [key: string]: string }) },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Card Production"
      size="md"
      formSize="md"
      gap={40}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <FormContent gap={16} title="Expiry, Renewal and PIN">
        <Form.Item
          name={NIC_CARD_SUBTYPE_EXP_MONTHS}
          label={
            <FormItemLabel
              label="Number of months the card is valid until expiry from the month of issuance"
              code={NIC_CARD_SUBTYPE_EXP_MONTHS}
            />
          }
          rules={[
            { required: true, message: "Number of months is required!" },
            {
              type: "number",
              min: minExpireLength,
              message: `Number of months must be larger than ${minExpireLength}.`,
            },
            { type: "number", max: 60, message: " Number of months must be lower than 60." },
          ]}
          initialValue={60}
        >
          <NetworkForm.Number placeholder="Number of months" />
        </Form.Item>

        <Form.Item
          name={NIC_CARD_SUBTYPE_AUTORENEW_DAYS}
          label={
            <FormItemLabel
              label="Number of days before card expiry for automated renewal"
              code={NIC_CARD_SUBTYPE_AUTORENEW_DAYS}
            />
          }
          rules={[
            () => ({
              validator(_, value) {
                return checkValueBetweenRangeValidator(value, "Number of days", 0, 90);
              },
            }),
          ]}
          initialValue={30}
        >
          <NetworkForm.Number />
        </Form.Item>

        <Space direction="horizontal">
          <Form.Item
            name={NIC_CARD_SUBTYPE_PINSET_ALLOWED}
            valuePropName="checked"
            rules={[{ required: true, message: "Pin Set is required!" }]}
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <FormItemLabel label="Allow PIN set" code={NIC_CARD_SUBTYPE_PINSET_ALLOWED} />
        </Space>

        <Space direction="horizontal">
          <Form.Item
            name={NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED}
            valuePropName="checked"
            rules={[{ required: true, message: "Change PIN is required!" }]}
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <TooltipInfo
            label="Allow changing of PIN for locked plastics"
            code={NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED}
            tooltipProps={{ title: "This allows your customer to change card PIN prior to activating it" }}
          />
        </Space>
      </FormContent>

      <FormContent gap={16} title="Additional Payment Schema Services">
        <Space direction="horizontal">
          <Form.Item
            name={NIC_CARD_SUBTYPE_ABU_ACTIVE}
            valuePropName="checked"
            rules={[{ required: true, message: "Automated updating card-on-file is required!" }]}
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <FormItemLabel
            label="Automated update of card-on-file information for recurring and non-recurring payments"
            code={NIC_CARD_SUBTYPE_ABU_ACTIVE}
          />
        </Space>

        <Space direction="horizontal">
          <Form.Item
            name={NIC_CARD_SUBTYPE_TOKEN_USED}
            valuePropName="checked"
            rules={[{ required: true, message: "Scheme tokenization is required!" }]}
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <TooltipInfo
            label="Tokenization (Visa/MasterCard)"
            code={NIC_CARD_SUBTYPE_TOKEN_USED}
            tooltipProps={{
              title:
                "Projects with Apple, Samsung, Google etc. need to be arranged separately by issuer banks. This enabled the CMS support.",
            }}
          />
        </Space>

        {(getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_MADA_USE) as string) === "true" && (
          <Space direction="horizontal">
            <Form.Item
              name={NIC_CARD_SUBTYPE_TOKEN_MADA_USED}
              valuePropName="checked"
              rules={[{ required: true, message: "Automated updating card-on-file is required!" }]}
            >
              <Switch />
            </Form.Item>
            <FormItemLabel label="Tokenization (MADA)" code={NIC_CARD_SUBTYPE_TOKEN_MADA_USED} />
          </Space>
        )}
      </FormContent>
    </CustomFormWrapper>
  );
};
