import { FC } from "react";
import { Form, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { usePct } from "@ni/product-settings";

import { enableOverlimitFee, OverlimitFeeOptions, OverlimitTolerancePercentageOptions } from "./OverlimitFee.constants";

export const OverlimitFeePage: FC = () => {
  const { productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [form] = Form.useForm<FormValues>();

  const { productCurrency: currencyType } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [enableOverlimitFee.code, OverlimitFeeOptions.code, OverlimitTolerancePercentageOptions.code],
    },
    allowParse: true,
  });

  const isOverlimitEnabled = Form.useWatch<boolean>(enableOverlimitFee.code, form);

  const onFinish = (values: FormValues) => {
    void onSavePct({ [OverlimitFeeOptions.code]: "", [OverlimitTolerancePercentageOptions.code]: "", ...values });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Over-limit Fee"
      pageSubtitle="Overlimit fee is charged when the utilization of a card exceeds the allowed credit limit."
      size="md"
      formSize="full"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item name={enableOverlimitFee.code} valuePropName="checked" initialValue={enableOverlimitFee.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo label={enableOverlimitFee.name} tooltipProps={{}} />
      </Space>
      {isOverlimitEnabled && (
        <>
          <Form.Item
            name={OverlimitFeeOptions.code}
            label={OverlimitFeeOptions.name}
            rules={[{ required: true, message: "Overlimit Fee value is required!" }]}
          >
            <NetworkForm.Number
              addonAfter={currencyType}
              min={OverlimitFeeOptions.min}
              precision={OverlimitFeeOptions.decimalsNumber}
            />
          </Form.Item>
          <Form.Item
            name={OverlimitTolerancePercentageOptions.code}
            label={OverlimitTolerancePercentageOptions.name}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: OverlimitTolerancePercentageOptions.tooltip,
            }}
          >
            <NetworkForm.Number
              addonAfter="%"
              min={OverlimitTolerancePercentageOptions.min}
              precision={OverlimitTolerancePercentageOptions.decimalsNumber}
            />
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
