import { FC } from "react";
import { Form } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { CheckboxGroup, CheckboxGroupProps } from "../../CheckboxGroup";
import { FormItemLabel } from "../../Labels";

export type NetworkCheckBoxProps = { formItemOptions?: NetWorkFormItemProps } & CheckboxGroupProps;

export const CheckBox: FC<NetworkCheckBoxProps> = ({ formItemOptions, ...props }) => {
  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        label={<FormItemLabel label={formItemOptions?.label} code={formItemOptions?.name as string} />}
      >
        <CheckboxGroup {...props} />
      </Form.Item>
    );
  }
  return <CheckboxGroup {...props} />;
};
