import { FC, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import { JF_ACC_ENABLED, NICE_JF_ACC_VAL } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const stringsKeys = [JF_ACC_ENABLED, NICE_JF_ACC_VAL];

export const JoiningFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm();
  const isEnabled = Form.useWatch<boolean>(JF_ACC_ENABLED, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "",
    [wizardResponse.product?.productValues],
  );

  const getJoinFeeVal = () => {
    switch (currency) {
      case "SAR":
      case "AED":
        return "300";

      case "USD":
        return "85";

      default:
        return "";
    }
  };

  useHydrateForm({
    form,
    entityFields: [
      { fieldCode: NICE_JF_ACC_VAL, value: getJoinFeeVal() },
      ...(wizardResponse.product?.parameterTables?.[0].pctProductValues ?? []),
    ],
    keys: {
      strings: stringsKeys,
    },
    allowParse: true,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    [...stringsKeys].forEach(key => {
      if (
        !values[key]?.toString() &&
        wizardResponse?.product?.parameterTables?.[0].pctProductValues?.find(item => item.fieldCode === key)
      ) {
        values[key] = "";
      }
    });

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Joining Fee"
      pageSubtitle="Joining fee is a one-time fee that is charged to customer upon account opening regardless of card
        activation status or number of cards under account."
      size="sm"
      formSize="sm"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item name={JF_ACC_ENABLED} initialValue={true} valuePropName="checked">
          <Switch />
        </Form.Item>
        <TooltipInfo
          label="Enable joining fee on your product"
          code={JF_ACC_ENABLED}
          tooltipProps={{
            title:
              "Joining fee will be charged on account once the account is boarded on system, regardless of number of cards",
          }}
        />
      </Space>

      {isEnabled && (
        <Form.Item
          name={NICE_JF_ACC_VAL}
          label={<FormItemLabel label="Joining fee amount" code={NICE_JF_ACC_VAL} />}
          rules={[
            {
              required: true,
              message: "Joining fee amount is required",
            },
          ]}
        >
          <NetworkForm.Number precision={2} min={0} addonAfter={currency} stringMode={true} disabled={formDisabled} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
