import { FC, forwardRef } from "react";
import { Form, Space, Switch as AntdSwitch, SwitchProps } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { FormItemLabel } from "../../Labels";

export type NetworkSwitchProps = { formItemOptions?: NetWorkFormItemProps } & SwitchProps;

export const Switch = forwardRef<HTMLButtonElement, NetworkSwitchProps>(({ formItemOptions, ...props }, ref) => {
  if (formItemOptions?.name) {
    return (
      <Space direction="horizontal">
        <Form.Item {...formItemOptions}>
          <AntdSwitch ref={ref} {...props} />
        </Form.Item>

        <FormItemLabel label={formItemOptions?.label} code={formItemOptions?.name as string} />
      </Space>
    );
  }
  return (
    <Space direction="horizontal">
      <AntdSwitch ref={ref} {...props} />
      <FormItemLabel label={formItemOptions?.label} code={formItemOptions?.name as string} />
    </Space>
  );
});
