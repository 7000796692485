import { FC, useEffect, useState } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { autoClousureOptions, periodRadioOptions } from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, RadioGroup } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ContractAutoClosurePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const nicAccStAutoClosePerTypeList = periodRadioOptions;
  const nicAccStAutoDormEodList = autoClousureOptions;
  const [balanceOwner, setBalanceOwner] = useState<string>();
  const [currSetup, setCurrSetup] = useState<string>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const checkBalanceOwner = (validating: string): boolean => {
    return validating === balanceOwner;
  };
  const checkMulticurrencyProducts = (validating: string): boolean => {
    return validating === currSetup;
  };

  useEffect(() => {
    form.setFieldsValue({
      "nic-acc-st-auto-close-per-type":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-acc-st-auto-close-per-type",
        ) || "M",
      "nic-acc-st-auto-close-per":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-acc-st-auto-close-per",
          "number",
        ) || 3,
      "nic-acc-st-auto-dorm-eod":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-acc-st-auto-dorm-eod",
        ) || "by_active_cards",
    });
    setBalanceOwner(
      getFormValueFromProductValues(wizardResponse.product?.productValues as ProductValue[], "balance-owner") as string,
    );
    setCurrSetup(
      getFormValueFromProductValues(wizardResponse.product?.productValues as ProductValue[], "curr-setup") as string,
    );
  }, [form, wizardResponse.product?.productValues]);

  const onFinish = (value: FormValues): void => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: value as { [key: string]: string },
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Contract Auto-Closure"
      formTitle="auto-closure (Dormancy) settings"
      pageSubtitle={
        <>
          {checkBalanceOwner("CBS") || checkMulticurrencyProducts("mult") ? (
            <div>Contract auto-closure can be triggered by dormancy factor below: </div>
          ) : (
            <div>Contract auto-closure can be triggered by different factors: </div>
          )}
          <ul>
            {checkBalanceOwner("CBS") || checkMulticurrencyProducts("mult") ? (
              <li>
                <div>
                  Dormancy due to card status, when no cards exist under the account except cancelled, replaced or
                  expired (for accounts) at end of billing cycle check.
                </div>
              </li>
            ) : (
              <>
                <li>
                  <div>
                    By dormancy due to card status, when no cards exist under the account except cancelled, replaced or
                    expired (for accounts) at end of billing cycle check.
                  </div>
                </li>
                <li>
                  <div>
                    By dormancy due to inactivity, when there are no financial transactions on card excluding fees and
                    charges throughout a full billing cycle.
                  </div>
                </li>
              </>
            )}
          </ul>
          <div>
            Triggering closure will change certain features as an immediate action like, blocking the authorization,
            Card Control feature. Contract will not be closed immediately once the closure process is triggered, instead
            based upon the parameter &quot;Pre-auto closure period&quot;, contract status is set to closure after the
            stipulated period. The main role of the &quot;Pre-auto closure period&quot; is allowing presentments to come
            from the payment scheme. After closure, the contract will be excluded from all reports and then purged as
            per the purge policy.
          </div>
        </>
      }
      size="sm"
      formSize="md"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      {checkBalanceOwner("CBS") || checkMulticurrencyProducts("mult") ? null : (
        <Form.Item
          name="nic-acc-st-auto-dorm-eod"
          label={<FormItemLabel label="Auto closure triggers" code="nic-acc-st-auto-dorm-eod" />}
        >
          <RadioGroup radioList={nicAccStAutoDormEodList} />
        </Form.Item>
      )}
      <Form.Item
        name="nic-acc-st-auto-close-per-type"
        label={<FormItemLabel label="Pre - auto closure period type" code="nic-acc-st-auto-close-per-type" />}
      >
        <RadioGroup radioList={nicAccStAutoClosePerTypeList} />
      </Form.Item>

      <Form.Item noStyle={true} dependencies={["nic-acc-st-auto-close-per-type"]}>
        {() => (
          <Form.Item
            name="nic-acc-st-auto-close-per"
            label={<FormItemLabel label="Pre - auto closure period number" code="nic-acc-st-auto-close-per" />}
            className="wizard-form-input"
            dependencies={["nic-acc-st-auto-close-per-type"]}
            rules={[
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(
                    value,
                    form.getFieldValue("nic-acc-st-auto-close-per-type") === "M"
                      ? "Number of months"
                      : "Number of days",
                    0,
                    form.getFieldValue("nic-acc-st-auto-close-per-type") === "M" ? 12 : 365,
                  );
                },
              }),
            ]}
          >
            <NetworkForm.Number />
          </Form.Item>
        )}
      </Form.Item>
    </CustomFormWrapper>
  );
};
