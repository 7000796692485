import { FC, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import { NIC_CCY, NIC_STMT_FEE_VAL, STMT_FEE_ENABLED } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const stringsKeys = [STMT_FEE_ENABLED, NIC_STMT_FEE_VAL];

export const StatementFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm<FormValues>();
  const isEnabled = Form.useWatch<boolean>(STMT_FEE_ENABLED, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY) as string) || "",
    [wizardResponse.product?.productValues],
  );

  const getStatFeeVal = () => {
    switch (currency) {
      case "SAR":
      case "AED":
        return "5";

      case "USD":
        return "1";

      default:
        return "";
    }
  };

  useHydrateForm({
    form,
    entityFields: [
      { fieldCode: NIC_STMT_FEE_VAL, value: getStatFeeVal() },
      ...(wizardResponse.product?.parameterTables?.[0].pctProductValues ?? []),
    ],
    keys: {
      strings: stringsKeys,
    },
    allowParse: true,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    [...stringsKeys].forEach(key => {
      if (
        !values[key]?.toString() &&
        wizardResponse?.product?.parameterTables?.[0].pctProductValues?.find(item => item.fieldCode === key)
      ) {
        values[key] = "";
      }
    });

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Statement Fee"
      pageSubtitle={
        <div>
          <TooltipInfo
            largeLabel="Statement fee is the fixed amount to charge to the customer with the following statement flags: E-mail
        statement, Physical statement, or Both."
            tooltipProps={{
              title:
                "After wizard completing you can configure client statement flags which are applicable for the fee charging. For this purpose visit product settings (Fees Product Configuration page)",
            }}
          />
          {"   "}
          The statement fee will be posted on billing date.
        </div>
      }
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item name={STMT_FEE_ENABLED} initialValue={true} valuePropName="checked" className="col-switch">
          <Switch />
        </Form.Item>
        <TooltipInfo label="Enable Statement fee for your product" code={STMT_FEE_ENABLED} tooltipProps={{}} />
      </Space>

      {isEnabled && (
        <Form.Item
          name={NIC_STMT_FEE_VAL}
          label={<FormItemLabel label="Statement fee amount" code={NIC_STMT_FEE_VAL} />}
          rules={[{ required: true, message: "Statement fee amount is required" }]}
        >
          <NetworkForm.Number precision={2} min={0} addonAfter={currency} stringMode={true} disabled={formDisabled} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
