import { forwardRef } from "react";
import { Form, Select as AntdSelect, SelectProps, GetRef } from "antd";
import { NetWorkFormItemProps, SelectOption } from "@ni/common/types";

import { FormItemLabel } from "../../Labels";

interface FormSelectProps extends SelectProps {
  optionList: SelectOption[];
}

export type NetworkSelectProps = { formItemOptions?: NetWorkFormItemProps } & FormSelectProps;

export const Select = forwardRef<GetRef<typeof AntdSelect>, NetworkSelectProps>(
  ({ optionList, formItemOptions, ...props }, ref) => {
    const SelectComponent = () => (
      <AntdSelect ref={ref} {...props}>
        {optionList.map(option => (
          <AntdSelect.Option key={option.value} value={option.value}>
            <FormItemLabel
              label={option.aliasLabel || option.label}
              code={option.key ?? option.value}
              tooltipProps={option?.tooltip ? { title: option.tooltip } : undefined}
            />
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    );

    if (formItemOptions?.name) {
      return (
        <Form.Item
          {...formItemOptions}
          label={<FormItemLabel label={formItemOptions?.label} code={formItemOptions?.name as string} />}
        >
          <SelectComponent />
        </Form.Item>
      );
    }

    return <SelectComponent />;
  },
);
