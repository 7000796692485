import { FC, useMemo, useState } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { useReduxState, useUATDisableForm } from "@ni/common/hooks";
import { GetProductWithId } from "@ni/common/types";
import { AfterWizardEditPageTitle, PageItemLayoutGeneral, TooltipInfo } from "@ni/common/ui";

import { ModalVisibilityConfiguration, PctList, PctModal } from "../../components";
import { usePct } from "../../hooks";

import styles from "./styles.module.scss";

const ModalVisibilityInitialState: ModalVisibilityConfiguration = {
  isOpen: false,
  type: "",
};

export const PctPage: FC = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();

  const [isLoading] = useReduxState<boolean>("isLoading", false);
  const [isPrintMode] = useReduxState<boolean>("isPrintMode");
  const [currentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);

  const { selectedPctId, setSelectedPctId, onCreatePct, onRenamePct, onDuplicatePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const navigate = useNavigate();

  const isDisabled = useUATDisableForm();

  const onNavigateToEditPct = (pctId: number) => {
    navigate(`/tenant/${tenantId}/product/${productId}/pct/${pctId}/settings-overview`);
  };

  const [modalVisibilityConf, setModalVisibilityConf] =
    useState<ModalVisibilityConfiguration>(ModalVisibilityInitialState);

  const pctList = useMemo(() => {
    return (currentProduct?.parameterTables?.slice() ?? []).sort((a, b) => (a.id || 0) - (b.id || 0));
  }, [currentProduct?.parameterTables]);

  const createClickHandler = () => {
    setModalVisibilityConf({ isOpen: true, type: "create" });
  };

  const renameClickHandler = (pctId: number) => {
    setSelectedPctId(pctId);
    setModalVisibilityConf({ isOpen: true, type: "rename" });
  };

  const duplicateClickHandler = (pctId: number) => {
    setSelectedPctId(pctId);
    setModalVisibilityConf({ isOpen: true, type: "duplicate" });
  };

  const onCancelRestoreToDefault = () => {
    setSelectedPctId(0);
    setModalVisibilityConf(ModalVisibilityInitialState);
  };

  return (
    <div className={styles["details-wrapper"]}>
      <PageItemLayoutGeneral size={16}>
        <AfterWizardEditPageTitle title="Pricing Control Tables" />
        <PageItemLayoutGeneral>
          <div className={styles["pct-tooltip"]}>
            <TooltipInfo
              largeLabel="In setting up fees, velocity controls, or other values for each product separate requirements could be
            applied to a specific segment of cardholders."
              tooltipProps={{
                title:
                  "While each product has one generalized pricing control table; you can still configure additional pricing tables that deliver different pricing propositions under the same product, for some of your cardholder segments, for example: Staff segment PCT, VIP customers PCT... etc.",
              }}
            />
          </div>
          <PctList
            pctList={pctList}
            isLoading={isLoading}
            onNavigateToEditPct={onNavigateToEditPct}
            renameClickHandler={renameClickHandler}
            duplicateClickHandler={duplicateClickHandler}
          />
          {!isPrintMode && (
            <Button
              loading={isLoading}
              className="save-button"
              type="primary"
              size="large"
              disabled={isDisabled}
              onClick={createClickHandler}
            >
              Add Pricing Table
            </Button>
          )}
        </PageItemLayoutGeneral>
      </PageItemLayoutGeneral>
      <PctModal
        currentName={currentProduct.parameterTables?.find(item => item.id === selectedPctId)?.displayName ?? ""}
        isOpen={modalVisibilityConf.isOpen}
        type={modalVisibilityConf.type}
        pctList={pctList}
        onCancel={onCancelRestoreToDefault}
        onDuplicatePct={onDuplicatePct}
        onCreatePct={onCreatePct}
        onRenamePct={onRenamePct}
      />
    </div>
  );
};
