import { FC, useMemo } from "react";
import { Form, Space } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  LTY_ACCR_B_RATE,
  LTY_ACCR_CAP_CTD,
  LTY_ACCR_RATE,
  LTY_ACCR_Y_RATE,
  LTY_PROGRAM_ACCR_FREQ,
  LTY_PROGRAM_ACCR_MODE,
  LTY_PROGRAM_ACCR_TYPE,
} from "@ni/common/constants";
import { useHydrateForm, useNumberOfTotalPointesCapDecimals } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, RadioGroup, Tabs } from "@ni/common/ui";
import { LoyaltyProgramTemplateType, Tenant } from "@ni/sdk/models";

import { LoyaltyProgramTemplatesPagesProps } from "../../types";

import { RADIO_GROUP_ACCRUAL_FREQ, RADIO_GROUP_ACCRUAL_MODE, RADIO_GROUP_ACCRUAL_TYPE } from "./constants";

interface PointAccrualSetupProps extends LoyaltyProgramTemplatesPagesProps {
  tenant: Tenant;
}

const stringsKeys = [
  LTY_ACCR_B_RATE,
  LTY_ACCR_CAP_CTD,
  LTY_ACCR_RATE,
  LTY_ACCR_Y_RATE,
  LTY_PROGRAM_ACCR_FREQ,
  LTY_PROGRAM_ACCR_MODE,
  LTY_PROGRAM_ACCR_TYPE,
];

export const PointAccrualSetup: FC<PointAccrualSetupProps> = ({
  loyaltyProgramTemplate,
  tenant,
  editLoyaltyProgramTemplate,
}) => {
  const [form] = Form.useForm<FormValues>();
  const decimalScale = useNumberOfTotalPointesCapDecimals(tenant);

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgramTemplate?.programValues ?? []),
        ...(loyaltyProgramTemplate?.programPctValues ?? []),
      ],
      keys: {
        strings: stringsKeys,
      },
      allowParse: true,
    },
    [loyaltyProgramTemplate],
  );

  const isCumulativeType = Form.useWatch<string>(LTY_PROGRAM_ACCR_TYPE, form) === "C";
  const accrualFrequency = Form.useWatch<string>(LTY_PROGRAM_ACCR_FREQ, form);

  const manipulateFormFields = () => {
    if (!isCumulativeType)
      return {
        shouldShowRate: true,
        shouldShowCap: true,
        accrualPercentageRateKey: LTY_ACCR_RATE,
        accrualPercentageRateTooltip:
          "This parameter specifies the rate at which points are accrued for the transaction made by the customer. This field is percentage value,  for example if it is set as 1% then points are accrued at the rate of 1% for transactions. ",
      };

    switch (accrualFrequency) {
      case "B":
        return {
          shouldShowRate: true,
          shouldShowCap: true,
          shouldShowTab: false,
          accrualPercentageRateKey: LTY_ACCR_B_RATE,
          accrualPercentageRateTooltip:
            "This parameter specifies the rate at which points are accrued for the eligible transactions in the cycle.",
        };
      case "Y":
        return {
          shouldShowRate: true,
          shouldShowCap: false,
          shouldShowTab: false,
          accrualPercentageRateKey: LTY_ACCR_Y_RATE,
          accrualPercentageRateTooltip:
            "This parameter specifies the rate at which points are accrued for the eligible transactions in the year",
        };
      default:
        return {
          shouldShowTab: true,
        };
    }
  };

  const { shouldShowRate, shouldShowCap, shouldShowTab, accrualPercentageRateKey, accrualPercentageRateTooltip } =
    useMemo(manipulateFormFields, [isCumulativeType, accrualFrequency]);

  const accrualPointTabs = useMemo(
    () =>
      loyaltyProgramTemplate?.id
        ? [
            {
              label: <>Billing Cycle</>,
              key: "B" as const,
              children: (
                <Space direction="vertical" size="large" className="w-p-100">
                  <Form.Item
                    name={LTY_ACCR_B_RATE}
                    required={true}
                    rules={[{ required: true }]}
                    label="Points accrual percentage rate"
                    tooltip={{
                      title:
                        "This parameter specifies the rate at which points are accrued for the eligible transactions in the cycle.",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <NetworkForm.Number stringMode={true} min={0} precision={2} addonAfter="%" />
                  </Form.Item>

                  <Form.Item
                    name={LTY_ACCR_CAP_CTD}
                    label="Total points cap per cycle"
                    tooltip={{
                      title:
                        "This parameter specifies about the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle to date transactions even though total accrued points are more than 500.",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <NetworkForm.Number stringMode={true} min={0} precision={decimalScale} />
                  </Form.Item>
                </Space>
              ),
            },
            {
              // eslint-disable-next-line no-irregular-whitespace
              label: <>  Yearly  </>,
              key: "Y" as const,
              children: (
                <Form.Item
                  name={LTY_ACCR_Y_RATE}
                  required={true}
                  rules={[{ required: true }]}
                  label="Points accrual percentage rate"
                  tooltip={{
                    title:
                      "This parameter specifies the rate at which points are accrued for the eligible transactions in the year",
                    icon: <QuestionCircleFilled />,
                  }}
                >
                  <NetworkForm.Number stringMode={true} min={0} precision={2} />
                </Form.Item>
              ),
            },
          ]
        : [],
    [decimalScale, loyaltyProgramTemplate?.id],
  );

  const onFinish = (values: FormValues) => {
    const alteredData: { values: FormValues; pctValues: FormValues; type: LoyaltyProgramTemplateType } = {
      values: {},
      pctValues: {},
      type: "NORMAL",
    };
    [...stringsKeys].forEach(key => {
      if (
        !values[key]?.toString() &&
        [...(loyaltyProgramTemplate?.programValues ?? []), ...(loyaltyProgramTemplate?.programPctValues ?? [])]?.find(
          item => item.code === key,
        )
      ) {
        values[key] = "";
      }
    });
    Object.keys(values).forEach(key => {
      if ([LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE].includes(key)) alteredData.values[key] = values[key];
      else if (key !== LTY_PROGRAM_ACCR_TYPE) alteredData.pctValues[key] = values[key];
      else alteredData.type = values[key] === "C" ? "CUMULATIVE" : "NORMAL";
    });
    editLoyaltyProgramTemplate(alteredData, "both");
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Accrual Setup"
      pageSubtitle="Loyalty points are earned for eligible transactions. Please define the basic accrual setup for the loyalty program."
      size="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item
        name={LTY_PROGRAM_ACCR_TYPE}
        label="Loyalty program accrual type:"
        initialValue={RADIO_GROUP_ACCRUAL_TYPE.default}
      >
        <RadioGroup radioList={RADIO_GROUP_ACCRUAL_TYPE.options} initialValue={RADIO_GROUP_ACCRUAL_TYPE.default} />
      </Form.Item>

      {isCumulativeType && (
        <>
          <Form.Item
            name={LTY_PROGRAM_ACCR_FREQ}
            label="Choose point accrual frequency:"
            required={true}
            initialValue={RADIO_GROUP_ACCRUAL_FREQ.default}
            rules={[{ required: true, message: "Please choose point accrual frequency" }]}
          >
            <RadioGroup radioList={RADIO_GROUP_ACCRUAL_FREQ.options} initialValue={RADIO_GROUP_ACCRUAL_FREQ.default} />
          </Form.Item>
          <Form.Item
            name={LTY_PROGRAM_ACCR_MODE}
            label="Choose cumulative program accrual mode:"
            initialValue={RADIO_GROUP_ACCRUAL_MODE.default}
            required={true}
            rules={[{ required: true, message: "Please choose cumulative program accrual mode" }]}
          >
            <RadioGroup radioList={RADIO_GROUP_ACCRUAL_MODE.options} initialValue={RADIO_GROUP_ACCRUAL_MODE.default} />
          </Form.Item>
        </>
      )}

      {shouldShowTab && (
        <Tabs
          pagesList={accrualPointTabs}
          discardAfterChangeTab={false}
          isCheckEnabled={true}
          form={form}
          initialValues={initialValues}
          onSave={onFinish}
        />
      )}

      {shouldShowRate && accrualPercentageRateKey && (
        <Form.Item
          name={accrualPercentageRateKey}
          required={true}
          rules={[{ required: true }]}
          label="Points accrual percentage rate"
          tooltip={{
            title: accrualPercentageRateTooltip,
            icon: <QuestionCircleFilled />,
          }}
        >
          <NetworkForm.Number stringMode={true} min={0} precision={2} addonAfter="%" />
        </Form.Item>
      )}
      {shouldShowCap && (
        <Form.Item
          name={LTY_ACCR_CAP_CTD}
          label="Total points cap per cycle"
          tooltip={{
            title:
              "This parameter specifies about the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle to date transactions even though total accrued points are more than 500.",
            icon: <QuestionCircleFilled />,
          }}
        >
          <NetworkForm.Number stringMode={true} min={0} precision={decimalScale} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
