import { Button, Form } from "antd";
import cn from "classnames";

import { DeleteOutlined } from "@ant-design/icons";
import { NetworkForm } from "@ni/common/ui";
import { checkDuplicate } from "@ni/common/utils";

import { EventCodes, RestrictionRuleItem, TabKeysCPF } from "../../../../types";

import { CARD_PRODUCTION_FEES_PREFIX } from "./constants";
import { RestictionRuleSwitch } from "./RestictionRuleSwitch";

import styles from "../../../../pages/pct/styles.module.scss";

interface RestrictionRuleProps {
  label: JSX.Element;
  rule: RestrictionRuleItem;
  currentTabKey: TabKeysCPF;
  rules: RestrictionRuleItem[];
  productCurrency: string;
  onRemoveRule: (id: number, array: RestrictionRuleItem[]) => void;
  onUpdateRuleFeeKey: (
    oldKey: string,
    checked: boolean,
    key: EventCodes,
    id: number,
    array: RestrictionRuleItem[],
    switchKey?: string,
  ) => void;
  onUpdateFeeAmount: (value: string, id: number, array: RestrictionRuleItem[]) => void;
}

export const RestrictionRule = ({
  label,
  rule: { id, feeKey, feeAmount, tabKey, keys },
  rules,
  currentTabKey,
  productCurrency,
  onRemoveRule,
  onUpdateRuleFeeKey,
  onUpdateFeeAmount,
}: RestrictionRuleProps) => {
  const getSwitchName = (key: string | undefined, ruleId: number) =>
    key ? `${CARD_PRODUCTION_FEES_PREFIX}-${currentTabKey}-${key}-enabled-${ruleId}` : "";

  const preembEnabledKey = getSwitchName(keys.preemb, id);
  const pinsEnabledKey = getSwitchName(keys.pins, id);
  const pinkEnabledKey = getSwitchName(keys.pink, id);
  const sdeEnabledKey = getSwitchName(keys.sde, id);

  const validateFeeAmount = () => {
    if (
      checkDuplicate(
        rules.map(fee => `${CARD_PRODUCTION_FEES_PREFIX}-${fee.tabKey}${fee.feeKey}`),
        `${CARD_PRODUCTION_FEES_PREFIX}-${tabKey}${feeKey}`,
      )
    ) {
      return Promise.reject(new Error("Options combination is duplicated, please change it or delete"));
    }

    if (feeAmount === null) {
      return Promise.reject(new Error("Fee amount is required"));
    }
    return Promise.resolve();
  };

  return (
    <div className={styles["pct-editing-box"]}>
      <Button
        type="text"
        danger={true}
        className={styles["pct-editing-box-remove_button"]}
        onClick={() => onRemoveRule(id, rules)}
      >
        <DeleteOutlined />
      </Button>

      <h3 className={cn(styles["pct-editing-box-title"], styles["lower_case"])}>{label}</h3>

      {keys.preemb && (
        <RestictionRuleSwitch
          name={preembEnabledKey}
          initialValue={feeKey.includes(keys.preemb)}
          title="Virtual card (physical plastic is not produced)"
          tooltip="Skipping producing of physical plastic"
          onChange={checked => onUpdateRuleFeeKey(feeKey, checked, keys.preemb as "preemb", id, rules, sdeEnabledKey)}
        />
      )}

      {keys.pins && (
        <RestictionRuleSwitch
          name={pinsEnabledKey}
          initialValue={feeKey.includes(keys.pins)}
          title="PIN set"
          tooltip="Ability for cardholder to set PIN using ATM, IVR, POS, Self Service"
          onChange={checked => onUpdateRuleFeeKey(feeKey, checked, keys.pins as "pins", id, rules, pinkEnabledKey)}
        />
      )}

      {keys.pink && (
        <RestictionRuleSwitch
          name={pinkEnabledKey}
          initialValue={feeKey.includes(keys.pink)}
          title="Keep the same PIN"
          tooltip="Skipping generation and producing of the new PIN code"
          onChange={checked => onUpdateRuleFeeKey(feeKey, checked, keys.pink as "pink", id, rules, pinsEnabledKey)}
        />
      )}

      {keys.sde && (
        <RestictionRuleSwitch
          name={sdeEnabledKey}
          initialValue={feeKey.includes(keys.sde)}
          title="Same day embossing"
          tooltip="Plastic and PIN (if applicable) is produced at the day of ordering (urgent issuing)"
          onChange={checked => onUpdateRuleFeeKey(feeKey, checked, keys.sde as "sde", id, rules, preembEnabledKey)}
        />
      )}

      <Form.Item
        className={styles["pct-select-item"]}
        name={feeKey ? `${CARD_PRODUCTION_FEES_PREFIX}-${currentTabKey}${feeKey}` : ""}
        label="Fee amount"
        rules={[
          {
            required: true,
            validator: validateFeeAmount,
          },
        ]}
      >
        <NetworkForm.Number
          min={0}
          precision={2}
          addonAfter={productCurrency}
          stringMode={true}
          disabled={!feeKey}
          onChange={value => onUpdateFeeAmount(value as string, id, rules)}
        />
      </Form.Item>
    </div>
  );
};
