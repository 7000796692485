import { FC } from "react";
import { Form } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { FormItemLabel } from "../../Labels";
import { RadioGroup, RadioGroupProps } from "../../Radio";

export type NetworkRadioProps = { formItemOptions?: NetWorkFormItemProps } & RadioGroupProps;

export const Radio: FC<NetworkRadioProps> = ({ formItemOptions, ...props }) => {
  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        label={<FormItemLabel label={formItemOptions?.label} code={formItemOptions?.name as string} />}
      >
        <RadioGroup {...props} />
      </Form.Item>
    );
  }

  return <RadioGroup {...props} />;
};
