import { FC } from "react";
import { Form, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useProductSettings, useRemainingCycleDayAfterDueDate } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { usePct } from "@ni/product-settings";

import {
  enableLatePaymentFeeOptions,
  latePaymentAmountVariance,
  latePaymentDateOptions,
  latePaymentFeeAmountOptions,
} from "./LatePaymentFee.constants";

export const LatePaymentFeePage: FC = () => {
  const { productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [form] = Form.useForm<FormValues>();

  const { productCurrency: currencyType, currentProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [
        enableLatePaymentFeeOptions.code,
        latePaymentAmountVariance.code,
        latePaymentDateOptions.code,
        latePaymentFeeAmountOptions.code,
      ],
    },
    allowParse: true,
  });

  const isLatePaymentFeeEnabled = Form.useWatch<boolean>(enableLatePaymentFeeOptions.code, form);
  const dueDateDays = useRemainingCycleDayAfterDueDate(currentProduct?.parameterTables![0].pctProductValues);

  const onFinish = (values: FormValues) => {
    void onSavePct({
      [latePaymentAmountVariance.code]: "",
      [latePaymentDateOptions.code]: "",
      [latePaymentFeeAmountOptions.code]: "",
      ...values,
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Late Payment Fee"
      pageSubtitle="Late Payment fee is charged to cardholder when the minimum payment is not made towards due amount until the configured late payment date."
      size="md"
      formSize="full"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item
          name={enableLatePaymentFeeOptions.code}
          valuePropName="checked"
          initialValue={enableLatePaymentFeeOptions.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo label={enableLatePaymentFeeOptions.name} tooltipProps={{}} />
      </Space>
      {isLatePaymentFeeEnabled && (
        <>
          <Form.Item
            name={latePaymentDateOptions.code}
            label={latePaymentDateOptions.name}
            initialValue={latePaymentDateOptions.default}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: latePaymentDateOptions.tooltip,
            }}
            rules={[
              {
                type: "number",
                max: (latePaymentDateOptions.max as number) - dueDateDays,
                message: "Late payment date cannot exceed remaining cycle days after setting a due date",
              },
            ]}
          >
            <NetworkForm.Number min={latePaymentDateOptions.min} precision={latePaymentDateOptions.decimalsNumber} />
          </Form.Item>
          <Form.Item
            name={latePaymentFeeAmountOptions.code}
            label={latePaymentFeeAmountOptions.name}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: latePaymentFeeAmountOptions.tooltip,
            }}
            rules={[{ required: true, message: "Late payment fee amount is required!" }]}
          >
            <NetworkForm.Number
              addonAfter={currencyType}
              min={latePaymentFeeAmountOptions.min}
              precision={latePaymentFeeAmountOptions.decimalsNumber}
            />
          </Form.Item>
          <Form.Item
            name={latePaymentAmountVariance.code}
            label={latePaymentAmountVariance.name}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: latePaymentAmountVariance.tooltip,
            }}
          >
            <NetworkForm.Number
              addonAfter={currencyType}
              min={latePaymentAmountVariance.min}
              precision={latePaymentAmountVariance.decimalsNumber}
            />
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
