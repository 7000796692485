import { forwardRef } from "react";
import { Form, Input, InputProps, InputRef } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { FormItemLabel } from "../../Labels";

export type NetworkStringProps = { formItemOptions?: NetWorkFormItemProps } & InputProps;

export const String = forwardRef<InputRef, NetworkStringProps>(({ formItemOptions, ...props }, ref) => {
  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        label={<FormItemLabel label={formItemOptions?.label} code={formItemOptions?.name as string} />}
      >
        <Input ref={ref} {...props} />
      </Form.Item>
    );
  }
  return <Input ref={ref} {...props} />;
});
