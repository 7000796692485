import { FC, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import {
  INACT_FEE_BILLING_EN,
  INACT_FEE_ONCE_EN,
  NIC_CCY,
  NIC_INACT_FEE_BILLING_VAL,
  NIC_INACT_FEE_ONCE_VAL,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const feeOnceMessage = "Amount charged once is required";
const feeBillingMessage = "Amount charged on the regular base is required";

const stringsKeys = [INACT_FEE_ONCE_EN, NIC_INACT_FEE_ONCE_VAL, INACT_FEE_BILLING_EN, NIC_INACT_FEE_BILLING_VAL];

export const InactivityFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm<FormValues>();
  const isFeeOnceEnabled = Form.useWatch<boolean>(INACT_FEE_ONCE_EN, form);
  const isFeeBillingEnabled = Form.useWatch<boolean>(INACT_FEE_BILLING_EN, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY) as string) || "",
    [wizardResponse.product?.productValues],
  );

  const getOnceFeeVal = () => {
    switch (currency) {
      case "SAR":
      case "AED":
        return "35";

      case "USD":
        return "10";

      default:
        return "";
    }
  };

  const getBaseFeeVal = () => {
    switch (currency) {
      case "SAR":
      case "AED":
        return "10";

      case "USD":
        return "3";

      default:
        return "";
    }
  };

  useHydrateForm({
    form,
    entityFields: [
      { fieldCode: NIC_INACT_FEE_ONCE_VAL, value: getOnceFeeVal() },
      { fieldCode: NIC_INACT_FEE_BILLING_VAL, value: getBaseFeeVal() },
      ...(wizardResponse.product?.parameterTables?.[0].pctProductValues ?? []),
    ],
    keys: {
      strings: stringsKeys,
    },
    allowParse: true,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Inactivity Fees"
      pageSubtitle="Inactivity fee is the fee charged when there is no activity on the account for specific period (3 months
        by default). The inactivity state is defined by absence of financial activities on card excluding fees and
        charges transactions."
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item name={INACT_FEE_ONCE_EN} initialValue={true} valuePropName="checked" className="col-switch">
          <Switch />
        </Form.Item>
        <TooltipInfo label="Enable one-time inactivity fee" code={INACT_FEE_ONCE_EN} tooltipProps={{}} />
      </Space>

      {isFeeOnceEnabled && (
        <Form.Item
          name={NIC_INACT_FEE_ONCE_VAL}
          label={
            <FormItemLabel label="Amount charged once (on exceeding inactivity period)" code={NIC_INACT_FEE_ONCE_VAL} />
          }
          rules={[{ required: true, message: feeOnceMessage }]}
        >
          <NetworkForm.Number precision={2} min={0} addonAfter={currency} stringMode={true} disabled={formDisabled} />
        </Form.Item>
      )}

      <Space direction="horizontal" align="center">
        <Form.Item name={INACT_FEE_BILLING_EN} initialValue={true} valuePropName="checked" className="col-switch">
          <Switch />
        </Form.Item>
        <TooltipInfo
          label="Enable regular inactivity fee"
          code={INACT_FEE_BILLING_EN}
          tooltipProps={{
            title: "This charge will be collected on each statement day for as long as inactive",
          }}
        />
      </Space>

      {isFeeBillingEnabled && (
        <Form.Item
          name={NIC_INACT_FEE_BILLING_VAL}
          label={
            <FormItemLabel
              label="Amount charged on the regular base (on statement day)"
              code={NIC_INACT_FEE_BILLING_VAL}
            />
          }
          rules={[{ required: true, message: feeBillingMessage }]}
        >
          <NetworkForm.Number precision={2} min={0} addonAfter={currency} stringMode={true} disabled={formDisabled} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
